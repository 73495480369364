import WebformElementWrapper from './WebformElementWrapper';

export default function WebformTextArea({
  element,
  value = '',
  setValue,
  fieldProps,
  error,
}) {
  const onChangeHandler = async (e) => {
    setValue(e.target.value);
  };

  return (
    <WebformElementWrapper
      labelFor={element['#key']}
      label={element['#title']}
      isRequired={element['#required']}
      access={element['#access']}
      settings={null}
      error={error}
    >
      <textarea
        className={`col-span-6 block w-full border-2 border-secondary bg-transparent focus:border-primary focus:ring-0 focus-visible:outline-none lg:col-span-4 ${
          error ? 'border-red-500' : ''
        }`}
        name={element['#webform_key']}
        value={value}
        onChange={(e) => onChangeHandler(e)}
        disabled={element['#disabled']}
        hidden={!element['#access']}
        required={element['#required']}
        readOnly={element['#readonly']}
        placeholder={element['#placeholder']}
        {...fieldProps}
        id={element['#id']}
      />
    </WebformElementWrapper>
  );
}
