import { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import Input from 'components/forms/Input';
import Textarea from 'components/forms/Textarea';
import Checkbox from 'components/forms/Checkbox';
import Select from 'components/forms/Select/Select';
import ArrowButton from 'components/Misc/ArrowButton';

import ErrorMessage from 'components/Misc/ErrorMessage';
import LoadingSpinner from 'components/Misc/LoadingSpinner';

import FileInput from 'components/forms/FileInput';
import { getError } from 'components/forms/utils';

type Inputs = {
  nameOfOrganization: string;
  sponsoringCategory: string;
  nameOfEvent: string;
  descriptionOfEvent: string;
  dateOfEvent: string;
  locationOfEvent: string;
  targetAudience: string;
  numberOfParticipants: string;
  howToSupport: string;
  serviceInReturn: Array<string>;
  additionalServiceInReturnValue: string;
  salutation: string;
  firstname: string;
  surname: string;
  address: string;
  postalcode: string;
  location: string;
  email: string;
  phoneNumber: string;
  uploads: FileList;
  dataProtection: boolean;
};

const LABELS = {
  nameOfOrganization: {
    label: (
      <FormattedMessage
        defaultMessage={`Name des Vereins / der Organisation`}
        id="2vXP6X"
      />
    ),
  },
  sponsoringCategory: {
    label: (
      <FormattedMessage defaultMessage={`Sponsoringkategorie`} id="S/gEwS" />
    ),
    options: [
      {
        value: 'Tombola',
        label: <FormattedMessage defaultMessage={`Tombola`} id="5Su6N4" />,
      },
      {
        value: 'Eventsponsoring',
        label: (
          <FormattedMessage defaultMessage={`Eventsponsoring`} id="vhGaYd" />
        ),
      },
      {
        value: 'Sonstige',
        label: <FormattedMessage defaultMessage={`Sonstige`} id="yQQ1qg" />,
      },
    ],
  },
  nameOfEvent: {
    label: (
      <FormattedMessage
        defaultMessage={`Name der Veranstaltung / des Projekts`}
        id="WFypti"
      />
    ),
  },
  descriptionOfEvent: {
    label: (
      <FormattedMessage
        defaultMessage={`Beschreibung der Veranstaltung`}
        id="sdSHuL"
      />
    ),
  },
  dateOfEvent: {
    label: (
      <FormattedMessage
        defaultMessage={`Datum der Veranstaltung`}
        id="aMUtRn"
      />
    ),
    description:
      'Reservation nicht für den gleichen Tag und nur MO - FR exkl. Feiertagen möglich',
  },
  locationOfEvent: {
    label: (
      <FormattedMessage defaultMessage={`Ort der Veranstaltung`} id="DgWLR7" />
    ),
  },
  targetAudience: {
    label: (
      <FormattedMessage
        defaultMessage={`Welches Zielpublikum wird bei Ihrer Veranstaltung angesprochen?`}
        id="ti9ZzX"
      />
    ),
    options: [
      {
        value: 'Kinder / Jugendliche < 18 Jahre',
        label: (
          <FormattedMessage
            defaultMessage={`Kinder / Jugendliche < 18 Jahre`}
            id="gtjjoi"
          />
        ),
      },
      {
        value: 'Junge Erwachsene < 25 Jahre',
        label: (
          <FormattedMessage
            defaultMessage={`Junge Erwachsene < 25 Jahre`}
            id="gXrtsM"
          />
        ),
      },
      {
        value: 'Erwachsene 26 - 45 Jahre',
        label: (
          <FormattedMessage
            defaultMessage={`Erwachsene 26 - 45 Jahre`}
            id="QAAHg4"
          />
        ),
      },
      {
        value: 'Erwachsene 46 - 75 Jahre',
        label: (
          <FormattedMessage
            defaultMessage={`Erwachsene 46 - 75 Jahre`}
            id="bQWG72"
          />
        ),
      },
      {
        value: '> 75 Jahre',
        label: <FormattedMessage defaultMessage={`> 75 Jahre`} id="pumMT4" />,
      },
    ],
  },
  numberOfParticipants: {
    label: (
      <FormattedMessage
        defaultMessage={`Wie viele Personen werden erwartet?`}
        id="wTLTnc"
      />
    ),
    options: [
      {
        value: '< 50',
        label: <FormattedMessage defaultMessage={`< 50`} id="dVi7c5" />,
      },
      {
        value: '51 - 250',
        label: <FormattedMessage defaultMessage={`51 - 250`} id="PB3I97" />,
      },
      {
        value: '251 - 500',
        label: <FormattedMessage defaultMessage={`251 - 500`} id="wRFZ1x" />,
      },
      {
        value: '1001 - 3000',
        label: <FormattedMessage defaultMessage={`1001 - 3000`} id="xLSIa5" />,
      },
      {
        value: '> 3001',
        label: <FormattedMessage defaultMessage={`> 3001`} id="yEsOKm" />,
      },
    ],
  },
  howToSupport: {
    label: (
      <FormattedMessage
        defaultMessage={`Was können wir zur erfolgreichen Durchführung Ihrer Veranstaltung beitragen?`}
        id="nKkhbj"
      />
    ),
  },
  serviceInReturn: {
    label: (
      <FormattedMessage
        defaultMessage={`Welche Gegenleistungen können Sie uns anbieten?`}
        id="eLD7Vq"
      />
    ),
    options: [
      {
        value: 'Logopräsenz (Web/Print)',
        label: (
          <FormattedMessage
            defaultMessage={`Logopräsenz (Web/Print)`}
            id="djBTtq"
          />
        ),
      },
      {
        value: 'Banner aufhängen',
        label: (
          <FormattedMessage defaultMessage={`Banner aufhängen`} id="3zx9Sf" />
        ),
      },
      {
        value: 'Inserat',
        label: <FormattedMessage defaultMessage={`Inserat`} id="QlFDl7" />,
      },
      {
        value: 'Social Media',
        label: <FormattedMessage defaultMessage={`Social Media`} id="5bGX59" />,
      },
      {
        value: 'Sonstige',
        label: <FormattedMessage defaultMessage={`Sonstige`} id="yQQ1qg" />,
      },
      {
        value: 'Keine',
        label: <FormattedMessage defaultMessage={`Keine`} id="j+yAD5" />,
      },
    ],
  },
  salutation: {
    label: <FormattedMessage defaultMessage={`Anrede`} id="em3wia" />,
    options: [
      {
        value: 'Herr',
        label: <FormattedMessage defaultMessage={`Herr`} id="UfS7Z0" />,
      },
      {
        value: 'Frau',
        label: <FormattedMessage defaultMessage={`Frau`} id="JLPcH7" />,
      },
    ],
  },
  firstname: {
    label: <FormattedMessage defaultMessage={`Vorname`} id="uzYEs0" />,
  },
  surname: {
    label: <FormattedMessage defaultMessage={`Nachname`} id="E46k0V" />,
  },
  address: {
    label: <FormattedMessage defaultMessage={`Adresse`} id="D24UoX" />,
  },
  postalcode: {
    label: <FormattedMessage defaultMessage={`PLZ`} id="9ti+Bw" />,
  },
  location: { label: <FormattedMessage defaultMessage={`Ort`} id="TZgWxf" /> },
  email: { label: <FormattedMessage defaultMessage={`E-Mail`} id="HEfhYI" /> },
  uploads: {
    label: <FormattedMessage defaultMessage={`Uploads`} id="ZMuAWH" />,
  },
  phoneNumber: {
    label: <FormattedMessage defaultMessage={`Telefonnummer`} id="35KTpe" />,
  },
};

export default function SponsoringForm({ paragraph }) {
  const intl = useIntl();
  const EMAIL_ERROR = getError('email', intl);
  const REQUIRED_ERROR = getError('required', intl);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm<Inputs>();
  const [error, setError] = useState(null);
  const isOpen =
    paragraph.field_webform.resourceIdObjMeta.status === 'open' ? true : false;
  const webformId =
    paragraph.field_webform.resourceIdObjMeta.drupal_internal__target_id;
  const [showInput, setShowInput] = useState(false);

  function handleCheckboxChange(e) {
    if (e.target.getAttribute('handle-checkbox-change') === 'true') {
      if (e.target.checked) {
        setShowInput(true);
      } else {
        setValue('additionalServiceInReturnValue', '');
        setShowInput(false);
      }
    }
  }

  async function uploadFiles(files) {
    const uploadedFiles = files.map(async (file: File) => {
      const formData = new FormData();
      // replace invalid characters with underscore
      const fileName = file.name.replace(/[^a-z0-9.-]/gi, '_').toLowerCase();

      formData.append('content', file);
      try {
        const response = await fetch(
          `${process.env.NEXT_PUBLIC_DRUPAL_BASE_URL}/webform_rest/${webformId}/upload/uploads?_format=json`,
          {
            method: 'POST',
            body: formData,
            headers: {
              'Content-Type': 'application/octet-stream',
              'Content-Disposition': `file; filename="${fileName}"`,
            },
          }
        );
        const responseData = await response.json();

        if (!response.ok) {
          throw new Error(responseData.message);
        }
        return responseData;
      } catch (error) {
        setError('Dateiupload fehlgeschlagen');
        return Promise.reject('file upload failed');
      }
    });

    return await Promise.all(uploadedFiles);
  }

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setError(null);
    let fileIds = [];
    if (data.uploads) {
      // upload files first
      const uploadResult = await uploadFiles(data.uploads);
      // get File ID
      fileIds = uploadResult.map((file) => file.fid[0].value);
    }

    try {
      const response = await fetch(
        `${process.env.NEXT_PUBLIC_DRUPAL_BASE_URL}/webform_rest/submit`,
        {
          method: 'POST',
          body: JSON.stringify({
            webform_id: webformId,
            verein: data.nameOfOrganization,
            sponsoringkategorie: data.sponsoringCategory,
            name_der_veranstaltung_des_projektes: data.nameOfEvent,
            anmerkungen: data.descriptionOfEvent,
            datum: data.dateOfEvent,
            ort_der_veranstaltung: data.locationOfEvent,
            welches_zielpublikum_wird_bei_ihrer_veranstaltung_angesprochen_:
              data.targetAudience,
            wie_viele_personen_werden_erwartet_: data.numberOfParticipants,
            was_koennen_wir_zur_erfolgreichen_durchfuehrung_ihrer_veranstalt:
              data.howToSupport,
            welche_gegenleistungen_koennen_sie_uns_anbieten_:
              data.serviceInReturn,
            anrede: data.salutation,
            vorname: data.firstname,
            name: data.surname,
            adresse: data.address,
            plz: data.postalcode,
            orts: data.location,
            e_mail: data.email,
            telefonnummer: data.phoneNumber,
            uploads: fileIds,
            datenschutz2: data.dataProtection,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message);
      }
    } catch (error) {
      setError(error.message);
      return Promise.reject('webform submit failed');
    }
  };

  if (!isOpen) {
    return (
      <div className="mx-auto max-w-4xl px-6 text-center lg:px-8">
        Dieses Formular ist geschlossen
      </div>
    );
  }

  if (isSubmitSuccessful) {
    return (
      <div className="mx-auto max-w-4xl px-6 text-center lg:px-8">
        <h3>Vielen Dank für Ihre Anfrage</h3>
      </div>
    );
  }

  return (
    <div
      data-paragraph-type="P15SponsoringForm"
      className="mx-auto max-w-4xl px-6 lg:px-8"
    >
      {error && <ErrorMessage message={error} />}
      <form className="relative" onSubmit={handleSubmit(onSubmit)}>
        {isSubmitting && (
          <div className="absolute z-10 flex h-full w-full items-center justify-center bg-white bg-opacity-30">
            <div className="mb-24">
              <LoadingSpinner size={10} />
            </div>
          </div>
        )}

        <div className="flex flex-col gap-2 lg:gap-5">
          <Input
            id="nameOfOrganization"
            type="text"
            label={LABELS.nameOfOrganization.label}
            error={errors.nameOfOrganization}
            register={register('nameOfOrganization', {
              required: REQUIRED_ERROR,
            })}
          />
          <Select
            id="sponsoringCategory"
            label={LABELS.sponsoringCategory.label}
            options={LABELS.sponsoringCategory.options}
            error={errors.sponsoringCategory}
            register={register('sponsoringCategory', {
              required: REQUIRED_ERROR,
            })}
          />
          <Input
            id="nameOfEvent"
            type="text"
            label={LABELS.nameOfEvent.label}
            error={errors.nameOfEvent}
            register={register('nameOfEvent', {
              required: REQUIRED_ERROR,
            })}
          />
          <Textarea
            id="descriptionOfEvent"
            label={LABELS.descriptionOfEvent.label}
            error={errors.descriptionOfEvent}
            register={register('descriptionOfEvent', {
              required: REQUIRED_ERROR,
            })}
          />
          <Input
            type="date"
            id="date"
            label={LABELS.dateOfEvent.label}
            description={LABELS.dateOfEvent.description}
            error={errors.dateOfEvent}
            register={register('dateOfEvent', {
              required: REQUIRED_ERROR,
            })}
          />
          <Input
            id="location"
            type="text"
            label={LABELS.locationOfEvent.label}
            error={errors.locationOfEvent}
            register={register('locationOfEvent', { required: REQUIRED_ERROR })}
          />
          <Select
            id="targetAudience"
            label={LABELS.targetAudience.label}
            options={LABELS.targetAudience.options}
            error={errors.targetAudience}
            register={register('targetAudience', { required: REQUIRED_ERROR })}
          />
          <Select
            id="numberOfParticipants"
            label={LABELS.numberOfParticipants.label}
            options={LABELS.numberOfParticipants.options}
            error={errors.numberOfParticipants}
            register={register('numberOfParticipants', {
              required: REQUIRED_ERROR,
            })}
          />
          <Textarea
            id="howToSupport"
            label={LABELS.howToSupport.label}
            error={errors.howToSupport}
            register={register('howToSupport', { required: REQUIRED_ERROR })}
          />
          <div className="mt-3 flex flex-col gap-y-1">
            <label className="text-base-m font-light lg:text-base-d">
              {LABELS.serviceInReturn.label}
            </label>
            {LABELS.serviceInReturn.options.map((option) => {
              return (
                <Checkbox
                  key={option.value}
                  id={option.value}
                  label={option.label}
                  value={option.value}
                  error={errors.serviceInReturn}
                  register={register('serviceInReturn', {
                    required: REQUIRED_ERROR,
                  })}
                />
              );
            })}
            <div>
              <Checkbox
                handle-checkbox-change="true"
                id="Weitere..."
                label="Weitere..."
                error={null}
                register={register('serviceInReturn', {
                  required: REQUIRED_ERROR,
                  // This eventhandler is set for all serviceInReturn checkboxes
                  onChange: (e) => {
                    handleCheckboxChange(e);
                  },
                })}
                value={watch('additionalServiceInReturnValue')}
              />
              {showInput && (
                <Input
                  label={''}
                  id="additionalServiceInReturnValue"
                  register={register('additionalServiceInReturnValue', {
                    required: REQUIRED_ERROR,
                    onChange: (e) => {
                      // Set the last value of the serviceInReturn array to the value of the input
                      const serviceInReturn = getValues('serviceInReturn');
                      serviceInReturn[serviceInReturn.length - 1] =
                        e.target.value;
                    },
                  })}
                  error={errors.additionalServiceInReturnValue}
                />
              )}
            </div>
          </div>
          <Select
            id="salutation"
            label={LABELS.salutation.label}
            options={LABELS.salutation.options}
            error={errors.salutation}
            register={register('salutation', {
              required: REQUIRED_ERROR,
            })}
          />
          <Input
            id="firstname"
            type="text"
            label={LABELS.firstname.label}
            error={errors.firstname}
            register={register('firstname', {
              required: REQUIRED_ERROR,
            })}
          />
          <Input
            type="text"
            id="surname"
            label={LABELS.surname.label}
            error={errors.surname}
            register={register('surname', {
              required: REQUIRED_ERROR,
            })}
          />
          <Input
            type="text"
            id="address"
            label={LABELS.address.label}
            error={errors.address}
            register={register('address', {
              required: REQUIRED_ERROR,
            })}
          />
          <Input
            type="text"
            id="postalcode"
            label={LABELS.postalcode.label}
            error={errors.postalcode}
            register={register('postalcode', {
              required: REQUIRED_ERROR,
            })}
          />
          <Input
            type="text"
            id="location"
            label={LABELS.location.label}
            error={errors.location}
            register={register('location', {
              required: REQUIRED_ERROR,
            })}
          />
          <Input
            type="email"
            id="email"
            label={LABELS.email.label}
            error={errors.email}
            register={register('email', {
              required: REQUIRED_ERROR,
              pattern: {
                value: /^\S+@\S+$/i,
                message: EMAIL_ERROR,
              },
            })}
          />
          <Input
            type="text"
            id="phone-number"
            label={LABELS.phoneNumber.label}
            error={errors.phoneNumber}
            register={register('phoneNumber', {
              required: REQUIRED_ERROR,
            })}
          />
          <FileInput
            error={errors.uploads}
            accept={{ 'application/pdf': ['.pdf'] }}
            name="uploads"
            id="uploads"
            label={LABELS.uploads.label}
            maxFiles={4}
            multiple={true}
            maxSize={100}
            setValue={setValue}
            watch={watch}
            register={register('uploads')}
          />
          <Checkbox
            id="data-protection"
            label={null}
            dataProtection={true}
            error={errors.dataProtection}
            register={register('dataProtection', {
              required: REQUIRED_ERROR,
            })}
          />
        </div>

        <div className="mt-12">
          <ArrowButton disabled={isSubmitting} type="submit">
            Absenden
          </ArrowButton>
        </div>
      </form>
    </div>
  );
}
