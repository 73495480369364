import useWindowSize from 'hooks/useWindowSize';
import LightBoxImage from 'components/Misc/LightBox/LightBoxImage';

export default function P5TextImage({ paragraph }) {
  let alignSideImageWithBottom = paragraph.field_top_image
    ? 'bottom-0 lg:bottom-24'
    : 'top-0';

  let alignSideVideoWithBottom = paragraph.field_top_video
    ? 'bottom-0 lg:bottom-24'
    : 'top-0';

  let reverse_class = '';
  if (paragraph.field_reverse == true) {
    reverse_class = 'flex-row-reverse';
  }
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 1024;
  return (
    <div>
      <div
        data-paragraph-type="P5TextImage"
        className="relative z-10 mx-auto max-w-4xl px-0 lg:px-8"
      >
        <div className={`flex-none gap-0 lg:flex lg:gap-8  ${reverse_class}`}>
          <div className="relative basis-0 lg:basis-1/2">
            {((!paragraph.field_side_video &&
              !paragraph.field_side_video_youtube_id) ||
              isMobile) && (
              <LightBoxImage
                src={
                  paragraph?.field_side_image?.field_media_image
                    ?.image_style_uri?.focal_point_side_image_300x400
                }
                alt={
                  paragraph?.field_side_image?.field_media_image
                    ?.field_image_alt_text || ''
                }
                className={`${alignSideImageWithBottom} relative h-[11.5rem] w-full object-cover lg:absolute lg:h-[25.5rem] lg:w-[20.25rem]`}
                width={648}
                height={818}
                allowLightBox={paragraph?.field_side_image?.field_lightbox}
                urlImageFullscreen={
                  paragraph?.field_side_image?.field_media_image?.uri?.url
                }
              />
            )}
            <div
              className={`${alignSideVideoWithBottom} relative h-full w-full`}
            >
              {!isMobile && paragraph.field_side_video_youtube_id && (
                <div
                  className={`${alignSideImageWithBottom} w-full overflow-hidden object-cover lg:absolute lg:h-[25.5rem] lg:w-[20.25rem]`}
                >
                  <iframe
                    className={`pointer-events-none absolute left-1/2 top-1/2 aspect-video h-[110%] min-h-full w-auto min-w-full -translate-x-1/2 -translate-y-[60%]`}
                    src={`https://www.youtube.com/embed/${paragraph.field_side_video_youtube_id}?controls=0&mute=1&autoplay=1&showinfo=0&rel=0&modestbranding=1&disablekb=1&loop=1&playlist=${paragraph.field_side_video_youtube_id}`}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  />
                </div>
              )}
              {!isMobile &&
                paragraph.field_side_video &&
                !paragraph.field_side_video_youtube_id && (
                  <video
                    autoPlay
                    loop
                    muted
                    playsInline
                    className="mb-[4.375rem] h-auto w-full lg:mb-0"
                  >
                    <source src={paragraph.field_side_video} type="video/mp4" />
                  </video>
                )}
            </div>
          </div>
          <div className="basis-0 lg:basis-1/2">
            {paragraph.field_top_image?.field_media_image && (
              <div className="mb-20">
                {((!paragraph.field_top_video &&
                  !paragraph.field_top_video_youtube_id) ||
                  isMobile) && (
                  <LightBoxImage
                    src={
                      paragraph?.field_top_image?.field_media_image
                        ?.image_style_uri?.focal_point_top_bottom_image_400x300
                    }
                    alt={
                      paragraph?.field_top_image?.field_media_image
                        ?.field_image_alt_text || ''
                    }
                    className=""
                    width={818}
                    height={648}
                    style={{ height: 'auto' }}
                    urlImageFullscreen={
                      paragraph?.field_top_image?.field_media_image?.uri?.url
                    }
                    allowLightBox={paragraph?.field_top_image?.field_lightbox}
                  />
                )}
              </div>
            )}
            {!isMobile && paragraph.field_top_video_youtube_id && (
              <div
                className={`relative h-[20rem] w-full overflow-hidden object-cover`}
              >
                <iframe
                  className={`pointer-events-none absolute left-1/2 top-1/2 aspect-video h-[125%] min-h-full w-auto min-w-full -translate-x-1/2 -translate-y-[60%]`}
                  src={`https://www.youtube.com/embed/${paragraph.field_top_video_youtube_id}?controls=0&mute=1&autoplay=1&showinfo=0&rel=0&modestbranding=1&disablekb=1&loop=1&playlist=${paragraph.field_top_video_youtube_id}`}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                />
              </div>
            )}
            {!isMobile &&
              paragraph.field_top_video &&
              !paragraph.field_top_video_youtube_id && (
                <div className="mb-20">
                  <video
                    autoPlay
                    loop
                    muted
                    playsInline
                    className="mb-[4.375rem] h-auto w-full lg:mb-0"
                  >
                    <source src={paragraph.field_top_video} type="video/mp4" />
                  </video>
                </div>
              )}
            <div className="mb-17.5 mt-17.5 hyphens-auto px-14 text-secondary lg:mb-20 lg:mt-0 lg:break-normal lg:px-0">
              <div
                dangerouslySetInnerHTML={{
                  __html: paragraph?.field_body_formatted?.processed,
                }}
              />
            </div>

            <div className="basis-1/2">
              {((!paragraph.field_bottom_video &&
                !paragraph.field_bottom_video_youtube_id) ||
                isMobile) && (
                <LightBoxImage
                  src={
                    paragraph?.field_bottom_image?.field_media_image
                      ?.image_style_uri?.focal_point_top_bottom_image_400x300
                  }
                  alt={
                    paragraph?.field_bottom_image?.field_media_image
                      ?.field_image_alt_text
                  }
                  className=""
                  width={818}
                  height={648}
                  style={{ height: 'auto' }}
                  urlImageFullscreen={
                    paragraph?.field_bottom_image?.field_media_image?.uri?.url
                  }
                  allowLightBox={paragraph?.field_bottom_image?.field_lightbox}
                />
              )}
              {!isMobile && paragraph.field_bottom_video_youtube_id && (
                <div
                  className={`relative h-[20rem] w-full overflow-hidden object-cover`}
                >
                  <iframe
                    className={`pointer-events-none absolute left-1/2 top-1/2 aspect-video h-[125%] min-h-full w-auto min-w-full -translate-x-1/2 -translate-y-[60%]`}
                    src={`https://www.youtube.com/embed/${paragraph.field_bottom_video_youtube_id}?controls=0&mute=1&autoplay=1&showinfo=0&rel=0&modestbranding=1&disablekb=1&loop=1&playlist=${paragraph.field_bottom_video_youtube_id}`}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  />
                </div>
              )}
              {!isMobile &&
                paragraph.field_bottom_video &&
                !paragraph.field_bottom_video_youtube_id && (
                  <video
                    autoPlay
                    loop
                    muted
                    playsInline
                    className="h-auto w-full"
                  >
                    <source
                      src={paragraph.field_bottom_video}
                      type="video/mp4"
                    />
                  </video>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
