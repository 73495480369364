import { absoluteUrl } from 'lib/utils';
import Image from 'next/image';

export default function P18PriceInfo({ paragraph }) {
  paragraph =
    paragraph?.field_price_info?.field_reusable_paragraph?.paragraphs ==
    undefined
      ? paragraph?.field_price_info
      : paragraph?.field_price_info?.field_reusable_paragraph?.paragraphs;

  const theme = paragraph?.field_theme ?? '';

  return (
    <div
      data-paragraph-type="P18PriceInfo"
      className="max-w mx-auto max-w-5xl px-14 lg:px-0"
    >
      {(paragraph?.field_title || paragraph?.field_byline) && (
        <div className="pb-14 text-secondary">
          <h2 className="mb-0 font-serif text-h2-m lg:text-h2-d">
            {paragraph?.field_title}
          </h2>
          <h3 className="mb-0 text-h3-m uppercase lg:text-h3-d">
            {paragraph?.field_byline}
          </h3>
        </div>
      )}
      <div className={`flex flex-col gap-x-14 bg-primary lg:flex-row ${theme}`}>
        <div className="relative h-32 flex-shrink-0 basis-auto md:h-64 lg:h-auto lg:basis-1/3">
          {paragraph?.field_image?.field_media_image && (
            <Image
              src={absoluteUrl(
                paragraph?.field_image?.field_media_image?.uri?.url
              )}
              alt={
                paragraph?.field_image?.field_media_image?.resourceIdObjMeta
                  ?.alt
              }
              fill
              sizes="(max-width: 1024px) 100vw, 500px"
              className="object-cover"
            />
          )}
        </div>
        <div className="flex flex-col gap-y-6 px-14 pt-8 pb-11 lg:px-0">
          <div
            className={
              'prose break-words text-base-m font-light text-secondary lg:break-normal lg:text-base-d'
            }
            dangerouslySetInnerHTML={{
              __html: paragraph?.field_formatted_title?.processed,
            }}
          />
          <div className="flex flex-col gap-y-9 gap-x-16 pr-4 sm:flex-row">
            <div
              className={
                'prose prose-invert text-base-m font-light text-tt-white lg:text-base-d'
              }
              dangerouslySetInnerHTML={{
                __html: paragraph?.field_first_column?.processed,
              }}
            />
            <span className="mx-auto w-11 border sm:hidden"></span>
            <div
              className={
                'prose prose-invert text-base-m font-light text-tt-white lg:text-base-d'
              }
              dangerouslySetInnerHTML={{
                __html: paragraph?.field_second_column?.processed,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
