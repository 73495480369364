import Alignment from 'components/Misc/Alignment';
import CurvedTitle from 'components/Misc/CurvedTitle';
import Heading from 'components/Misc/Heading';
import Subtitle from 'components/Misc/Subtitle';

export default function P2Text({ paragraph }) {
  const wideLayout = paragraph.field_wide_layout;

  return (
    <div
      data-paragraph-type="P2Text"
      className={`${
        wideLayout ? 'max-w-7xl' : 'max-w-4xl'
      } mx-auto px-0 lg:px-8`}
    >
      {paragraph.field_wide_layout}
      <Alignment alignment={paragraph.field_alignment}>
        <div className="px-14 text-secondary lg:px-0">
          <Heading isH1={paragraph?.hasH1}>
            <CurvedTitle isSpan={paragraph?.hasH1}>
              {paragraph?.field_title}
            </CurvedTitle>
            <Subtitle isSpan={paragraph?.hasH1}>
              {paragraph?.field_byline}
            </Subtitle>
          </Heading>
          <div
            className={`hyphens-auto text-base-m font-light lg:text-base-d ${
              !wideLayout && 'prose'
            }`}
            dangerouslySetInnerHTML={{
              __html: paragraph?.field_body_formatted?.processed,
            }}
          />
        </div>
      </Alignment>
    </div>
  );
}
