export default function P99Space({ paragraph }) {
  const mobileSizes = {
    'x-small': 'h-8.75',
    small: 'h-17.5',
    medium: 'h-25',
    big: 'h-50',
  };
  const desktopSizes = {
    'x-small': 'lg:h-12.5',
    small: 'lg:h-25',
    medium: 'lg:h-50',
    big: 'lg:h-100',
  };

  return (
    <div
      data-paragraph-type="P99Space"
      className={`w-full ${mobileSizes[paragraph.field_mobile_space] ?? ''} ${
        desktopSizes[paragraph.field_desktop_space] ?? ''
      }`}
    ></div>
  );
}
