import InformationTeaserSlider from './InformationTeaserSlider';
import InformationTeaserGallery from './InformationTeaserGallery';

export default function P9InformationTeaser({ paragraph }) {
  const cards = paragraph.field_information_teaser_cards;

  return (
    <div>
      {cards?.length > 3 ? (
        <InformationTeaserSlider cards={cards} />
      ) : (
        <InformationTeaserGallery cards={cards} />
      )}
    </div>
  );
}
