import { useState } from 'react';
import WebformElementWrapper from './WebformElementWrapper';

export default function WebformSelect({
  element,
  setValue,
  value = '',
  fieldProps,
}) {
  const [showOther, setShowOther] = useState(false);

  // Used to get the options for select other elements that have the options nested one level deeper.
  function getOptions() {
    let options = {};
    for (const key of Object.keys(element)) {
      if (key == 'select') {
        options = element[key];
      }
    }
    return options;
  }

  const optionsForOther =
    element['#type'] === 'webform_select_other' ? getOptions() : null;

  const onChangeHandler = async (e) => {
    if (e.target.value == 'Other') {
      setShowOther(!showOther);
    } else if (showOther) {
      setShowOther(!showOther);
      setValue(e.target.value);
    } else {
      setValue(e.target.value);
    }
  };

  const onInputHandler = async (e) => {
    setValue(e.target.value);
  };

  return (
    <WebformElementWrapper
      labelFor={element['#key']}
      label={element['#title']}
      isRequired={element['#required']}
      access={element['#access']}
      settings={null}
    >
      <select
        name={element['#name']}
        onChange={(e) => onChangeHandler(e)}
        value={value}
        disabled={element['#disabled']}
        hidden={!element['#access']}
        required={element['#required']}
        {...fieldProps}
        id={element['#id']}
        className="col-span-6 block w-full border-2 border-secondary bg-transparent p-2 focus:border-primary focus:outline-none focus:ring-0 lg:col-span-4"
      >
        {element['#webform_plugin_id'] == 'select' ||
        (typeof element['#webform_plugin_id'] === 'undefined' &&
          element['#type'] !== 'webform_select_other')
          ? Object.keys(element['#options']).map((key: string | number) => {
              return (
                <option value={key} key={key}>
                  {element['#options'][key]}
                </option>
              );
            })
          : null}
        {element['#webform_plugin_id'] == 'webform_entity_select'
          ? Object.keys(element['#options']).map(
              (entityId: number | string) => {
                const entityName = element['#options'][entityId];
                return (
                  <option key={entityId} value={entityId}>
                    {entityName}
                  </option>
                );
              }
            )
          : null}
        {element['#type'] === 'webform_select_other' &&
        optionsForOther['#options'] ? (
          <>
            {Object.values(optionsForOther['#options']).map((option, index) => {
              if (option != 'Other…') {
                return (
                  <option value={option.toString()} key={index}>
                    {option}
                  </option>
                );
              }
            })}
            <option value="Other">Other...</option>
          </>
        ) : null}
      </select>
      {showOther ? (
        <input
          type="text"
          id={element['#webform_key']}
          name={element['#webform_key']}
          onInput={(e) => onInputHandler(e)}
          value={value}
        />
      ) : null}
    </WebformElementWrapper>
  );
}
