import WebformElementWrapper from './WebformElementWrapper';

const supportedTypes = {
  textfield: 'text',
  email: 'email',
  number: 'number',
  tel: 'tel',
  time: 'time',
  url: 'url',
};
export default function WebformText({
  element,
  error,
  value = '',
  setValue,
  fieldProps,
}) {
  if (!(element['#type'] in supportedTypes)) {
    console.warn(
      `${element['#type']} which was used on ${element['#webform_key']} is not supported by WebformText.`
    );
  }
  const onChangeHandler = async (e) => {
    setValue(e.target.value);
  };

  return (
    <WebformElementWrapper
      labelFor={element['#key']}
      label={element['#title']}
      isRequired={element['#required']}
      access={element['#access']}
      settings={null}
      error={error}
    >
      <input
        className={`col-span-6 block w-full border-0 border-b-2 border-secondary bg-transparent focus:border-primary focus:ring-0 focus-visible:outline-none lg:col-span-4 ${
          error ? 'border-red-500' : ''
        }`}
        type={supportedTypes[element['#type']] ?? element['#type']}
        name={element['#name']}
        value={value}
        onChange={(e) => onChangeHandler(e)}
        disabled={element['#disabled']}
        hidden={!element['#access']}
        required={element['#required']}
        readOnly={element['#readonly']}
        placeholder={element['#placeholder']}
        {...fieldProps}
        id={element['#id']}
      />
    </WebformElementWrapper>
  );
}
