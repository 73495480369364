import { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';

import Input from 'components/forms/Input';
import Checkbox from 'components/forms/Checkbox';
import Select from 'components/forms/Select/Select';
import ArrowButton from 'components/Misc/ArrowButton';

import ErrorMessage from 'components/Misc/ErrorMessage';
import LoadingSpinner from 'components/Misc/LoadingSpinner';

import { getError } from 'components/forms/utils';
import { FormattedMessage, useIntl } from 'react-intl';

type Inputs = {
  salutation: string;
  firstname: string;
  surname: string;
  email: string;
  date_of_birth: string;
  interests: Array<string>;
  dataProtection: boolean;
};

const LABELS = {
  salutation: {
    label: <FormattedMessage defaultMessage={`Anrede`} id="em3wia" />,
    options: [
      {
        value: 'Herr',
        label: <FormattedMessage defaultMessage={`Herr`} id="UfS7Z0" />,
      },
      {
        value: 'Frau',
        label: <FormattedMessage defaultMessage={`Frau`} id="JLPcH7" />,
      },
    ],
  },
  firstname: {
    label: <FormattedMessage defaultMessage={`Vorname`} id="uzYEs0" />,
  },
  surname: {
    label: <FormattedMessage defaultMessage={`Nachname`} id="E46k0V" />,
  },
  email: { label: <FormattedMessage defaultMessage={`E-Mail`} id="HEfhYI" /> },
  date_of_birth: {
    label: <FormattedMessage defaultMessage={`Geburtsdatum`} id="D7S5YM" />,
  },
  interests: {
    label: (
      <FormattedMessage
        defaultMessage={`Welche Bereiche interessieren Sie am meisten?`}
        id="P7fzZF"
      />
    ),
    options: [
      {
        value: 'TT Ragazer Saunawelt',
        label: (
          <FormattedMessage defaultMessage={`Ragazer Saunawelt`} id="LmDNKp" />
        ),
      },
      {
        value: 'TT Kosmetik',
        label: <FormattedMessage defaultMessage={`Kosmetik`} id="3mhv9K" />,
      },
      {
        value: 'TT haki',
        label: <FormattedMessage defaultMessage={`haki`} id="3y+m2j" />,
      },
      {
        value: 'TT Skinfit',
        label: <FormattedMessage defaultMessage={`Skinfit`} id="I3puP1" />,
      },
      {
        value: 'TT Kids Kurse',
        label: <FormattedMessage defaultMessage={`Kids Kurse`} id="+c9QJO" />,
      },
      {
        value: 'TT Firmen- & Vereinsangebote',
        label: (
          <FormattedMessage
            defaultMessage={`Firmen- & Vereinsangebote`}
            id="fGg2LF"
          />
        ),
      },
      {
        value: 'Zimmer & Suiten',
        label: (
          <FormattedMessage
            defaultMessage={`Grand Resort (Ich möchte auch den Newsletter des Grand Resort Bad Ragaz erhalten)`}
            id="o87HsG"
          />
        ),
      },
    ],
  },
};

export default function NewsletterForm({ paragraph }) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm<Inputs>();
  const intl = useIntl();
  const EMAIL_ERROR = getError('email', intl);
  const REQUIRED_ERROR = getError('required', intl);
  const [error, setError] = useState(null);
  const isOpen =
    paragraph.field_webform.resourceIdObjMeta.status === 'open' ? true : false;

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setError(null);

    const formData = new FormData();
    formData.append('account_id', '13858');
    formData.append('account_code', 'qTY6C');
    formData.append('optinsetup_id', '13');
    formData.append('optinsetup_code', 'TgV9K');
    formData.append('ic', '38165');
    formData.append('fields[1]', data.email);
    formData.append('fields[2]', data.salutation);
    formData.append('fields[3]', data.firstname);
    formData.append('fields[4]', data.surname);
    formData.append('fields[5]', data.date_of_birth);
    if (data.interests.length > 0) {
      data.interests.forEach((interest) => {
        formData.append('fields[73][]', interest);
      });
    }
    formData.append('fields[9]', data.dataProtection ? 'on' : 'off');

    try {
      const response = await fetch(
        'https://mailing.resortragaz.ch/optin/optin/execute',
        {
          method: 'POST',
          body: formData,
        }
      );

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message);
      }
    } catch (error) {
      setError(error);
    }
  };

  if (!isOpen) {
    return (
      <div className="mx-auto max-w-4xl px-6 text-center lg:px-8">
        <FormattedMessage
          defaultMessage={`Formular ist geschlossen`}
          id="jEqcT3"
        />
      </div>
    );
  }

  if (isSubmitSuccessful) {
    return (
      <div className="mx-auto my-6 max-w-4xl px-6 lg:px-8">
        <p className="text-base font-normal lg:text-xl">
          <FormattedMessage
            defaultMessage={`BESTÄTIGEN SIE IHRE E-MAIL-ADRESSE`}
            id="j0+w6U"
          />
        </p>
        <p>
          <FormattedMessage
            defaultMessage={`Vielen Dank, dass Sie unseren Newsletter abonniert haben. Sie erhalten
          in Kürze eine E-Mail mit einem Link zur Bestätigung. Um die Anmeldung
          abzuschliessen, klicken Sie bitte auf den Link in Ihrem Mail. Wir
          freuen uns jetzt schon, Sie zukünfitg über News, Angebote,
          Veranstaltungen, Gewinnspiele, attraktive Aktionen und vieles mehr der
          Tamina Therme informiert.`}
            id="45p0np"
          />
        </p>
      </div>
    );
  }

  return (
    <div
      data-paragraph-type="P15NewsletterForm"
      className="mx-auto max-w-2xl px-6 lg:px-8"
    >
      {error && <ErrorMessage message={error.message} />}
      <form className="relative" onSubmit={handleSubmit(onSubmit)}>
        {isSubmitting && (
          <div className="absolute z-10 flex h-full w-full items-center justify-center bg-white bg-opacity-30">
            <div className="mb-24">
              <LoadingSpinner size={10} />
            </div>
          </div>
        )}

        <div className="flex flex-col gap-2 lg:gap-5">
          <Input
            type="email"
            id="email"
            label={LABELS.email.label}
            error={errors.email}
            register={register('email', {
              required: REQUIRED_ERROR,
              pattern: {
                value: /^\S+@\S+$/i,
                message: EMAIL_ERROR,
              },
            })}
          />
          <Select
            id="salutation"
            label={LABELS.salutation.label}
            options={LABELS.salutation.options}
            error={errors.salutation}
            register={register('salutation', {
              required: REQUIRED_ERROR,
            })}
          />
          <Input
            id="firstname"
            type="text"
            label={LABELS.firstname.label}
            error={errors.firstname}
            register={register('firstname', {
              required: REQUIRED_ERROR,
            })}
          />
          <Input
            type="text"
            id="surname"
            label={LABELS.surname.label}
            error={errors.surname}
            register={register('surname', {
              required: REQUIRED_ERROR,
            })}
          />
          <Input
            type="date"
            id="date"
            label={LABELS['date_of_birth'].label}
            error={errors['date_of_birth']}
            register={register('date_of_birth', {
              required: REQUIRED_ERROR,
            })}
          />
          <div className="-mb-2 mt-4 font-normal">{LABELS.interests.label}</div>
          {LABELS.interests.options.map((option) => (
            <Checkbox
              key={option.value}
              id={option.value}
              label={option.label}
              value={option.value}
              error={errors.interests}
              register={register('interests')}
            />
          ))}
          <div className="-mb-2 mt-4 font-normal">
            <FormattedMessage
              defaultMessage={`Datenschutzerklärung`}
              id="EuKMCb"
            />
          </div>
          <Checkbox
            id="data-protection"
            label={null}
            dataProtection={true}
            error={errors.dataProtection}
            register={register('dataProtection', {
              required: REQUIRED_ERROR,
            })}
          />
        </div>

        <div className="mt-12">
          <ArrowButton disabled={isSubmitting} type="submit">
            <FormattedMessage defaultMessage={`Absenden`} id="XoNxTi" />
          </ArrowButton>
        </div>
      </form>
    </div>
  );
}
