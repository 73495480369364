export default function Input({
  id = null,
  label = null,
  classNameLabel = 'col-span-2 pt-1 text-base-m font-light lg:text-base-d',
  description = null,
  error = null,
  register = null,
  ...props
}) {
  return (
    <div className="mt-2 grid grid-cols-6 gap-x-2 gap-y-2 lg:gap-x-4">
      <label htmlFor={id} className={classNameLabel}>
        {label}
      </label>

      <input
        className={`col-span-6 block w-full border-0 border-b-2 border-secondary bg-transparent focus:border-primary focus:ring-0 focus-visible:outline-none lg:col-span-4 ${
          error ? 'border-red-500' : ''
        }`}
        id={id}
        {...props}
        {...register}
      />

      {error && (
        <span className="col-start-1 col-end-7 text-sm text-red-500 lg:col-start-3">
          {error.message}
        </span>
      )}

      {description && (
        <span className="col-start-1 col-end-7 text-sm text-gray-500 lg:col-start-3">
          {description}
        </span>
      )}
    </div>
  );
}
