import CurvedTitle from 'components/Misc/CurvedTitle';
import Heading from 'components/Misc/Heading';
import Subtitle from 'components/Misc/Subtitle';
import { absoluteUrl } from 'lib/utils';
import Image from 'next/image';

const produceTitle = (paragraph) => {
  if (paragraph.hasH1) {
    return (
      <h1 className="mb-4 max-w-full px-6 lg:mb-16 lg:basis-3/4 lg:px-8">
        <CurvedTitle className="text-tt-white" isSpan={paragraph.hasH1}>
          {paragraph.field_title}
        </CurvedTitle>
        <Subtitle className="text-tt-white" isSpan={paragraph.hasH1}>
          {paragraph.field_byline}
        </Subtitle>
      </h1>
    );
  }
  return (
    <div className="mb-4 max-w-full px-6 lg:mb-16 lg:basis-3/4 lg:px-8">
      <CurvedTitle className="text-tt-white">
        {paragraph.field_title}
      </CurvedTitle>
      <Subtitle className="text-tt-white">{paragraph.field_byline}</Subtitle>
    </div>
  );
};

export default function P11TextIntroduction({ paragraph }) {
  return (
    <div
      data-paragraph-type="P11TextIntroduction"
      className="relative mx-auto max-w-6xl pt-16"
    >
      <div
        className={'absolute right-0 top-0 h-3/4 w-screen bg-secondary'}
      ></div>
      <div className="relative flex">
        <Heading
          className="mb-4 max-w-full px-6 lg:mb-16 lg:basis-3/4 lg:px-8"
          isH1={paragraph?.hasH1}
        >
          <CurvedTitle className="text-tt-white" isSpan={paragraph?.hasH1}>
            {paragraph.field_title}
          </CurvedTitle>
          <Subtitle className="text-tt-white" isSpan={paragraph?.hasH1}>
            {paragraph.field_byline}
          </Subtitle>
        </Heading>
      </div>
      <div className="relative mx-auto mb-10 max-w-4xl px-6 lg:mb-20 lg:px-8">
        {paragraph?.field_body_formatted?.processed && (
          <div
            className={
              'prose text-base-m font-light text-tt-white prose-strong:text-white lg:text-base-d'
            }
            dangerouslySetInnerHTML={{
              __html: paragraph.field_body_formatted.processed,
            }}
          />
        )}
      </div>

      <div className="relative mx-auto h-128 max-w-6xl overflow-hidden">
        {paragraph?.field_image?.field_media_image && (
          <Image
            src={absoluteUrl(paragraph.field_image.field_media_image.uri.url)}
            alt={paragraph.field_image.field_media_image.resourceIdObjMeta.alt}
            fill
            sizes="(max-width: 1024px) 100vw, 1200px"
            className="object-cover lg:pl-40"
          />
        )}
      </div>
    </div>
  );
}
