import { useEffect, useState } from 'react';
import { XMLParser } from 'fast-xml-parser';
import InstagramPost from './InstagramPost';

export default function P20InstagramWall({ paragraph }) {
  const feedURL = paragraph?.field_feed_url;
  const feedLimit = paragraph?.field_posts_limit;
  const [posts, setPosts] = useState([]);
  const options = {
    ignoreAttributes: false,
  };
  const xmlParser = new XMLParser(options);

  // parse the XML
  function parseXML(xml) {
    const feedObject = xmlParser.parse(xml);
    const feedItems = feedObject?.rss?.channel?.item;
    if (!Array.isArray(feedItems)) return;
    const filteredPosts = feedItems.map((item) => {
      return {
        title: item.title,
        link: item.link,
        image: item['media:content']['@_url'],
        date: item.pubDate,
      };
    });
    setPosts(filteredPosts);
  }

  // fetch the Instagram Feed
  async function fetchFeed() {
    if (!feedURL) return;
    const feed = await fetch(feedURL);
    const feedXML = await feed.text();
    parseXML(feedXML);
  }

  useEffect(() => {
    fetchFeed();
  }, []);

  const postsToShow = posts.slice(0, feedLimit ? feedLimit : posts.length);

  return (
    <div
      data-paragraph-type="P20InstagramWall"
      className="max-w mx-auto max-w-5xl px-14 lg:px-8"
    >
      <div className="grid auto-rows-[280px] grid-cols-1 gap-4 sm:auto-rows-[310px] sm:grid-cols-2 lg:grid-cols-3">
        {postsToShow.map((post) => (
          <InstagramPost
            key={post.title}
            title={post.title}
            image={post.image}
            link={post.link}
          />
        ))}
      </div>
    </div>
  );
}
