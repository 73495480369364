export default function WebfromWrapper({ children }) {
  return (
    <div
      data-paragraph-type="P15Webform"
      className="mx-auto max-w-4xl px-6 lg:px-8"
    >
      {children}
    </div>
  );
}
