import ArrowLink from 'components/Misc/ArrowLink';
import { absoluteUrl } from 'lib/utils';
import Image from 'next/image';
import Link from 'next/link';

const productTypesLinkText = {
  'product--voucher': 'Kaufen',
  'product--ticket': 'Kaufen',
  'product--retail-products': 'Kaufen',
  'product--course': 'Buchen',
  'product--reservation': 'Buchen',
  'product--package': 'Buchen',
};

export default function P14ProductCards({ paragraph }) {
  return (
    <div
      data-paragraph-type="P14ProdcutCards"
      className="mx-auto max-w-5xl px-14 lg:px-8"
    >
      <div className="grid grid-cols-1 gap-y-8 gap-x-4 sm:grid-cols-2 lg:grid-cols-3">
        {paragraph.field_products?.map((card, index) => (
          <div key={index} className="relative h-96 w-full bg-primary lg:h-128">
            {card.field_image?.field_media_image && (
              <Link href={card?.path?.alias ?? ''} passHref>
                <Image
                  width={324}
                  height={2}
                  src={absoluteUrl(
                    card?.field_image?.field_media_image?.uri?.url
                  )}
                  alt={
                    card?.field_image?.field_media_image?.resourceIdObjMeta?.alt
                  }
                  className="h-32 w-full object-cover lg:h-48"
                />
              </Link>
            )}
            <div className="text-clip py-4 px-6 lg:py-8 lg:px-8">
              <Link href={card?.path?.alias ?? ''} passHref>
                <h3 className="mb-4 text-h3-m font-light uppercase text-tt-white line-clamp-2 lg:text-link-d">
                  {card.title}
                </h3>
              </Link>
              {card?.field_product_teaser && (
                <div
                  className="text-base-m font-light text-tt-white line-clamp-5 lg:text-base-d"
                  dangerouslySetInnerHTML={{
                    __html: card?.field_product_teaser,
                  }}
                ></div>
              )}
              <div className="absolute bottom-0 left-0 right-0 px-6 py-4 lg:px-8 lg:py-8">
                <ArrowLink inverted href={card?.path?.alias ?? ''}>
                  {productTypesLinkText[card?.type] ?? 'Kaufen'}
                </ArrowLink>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
