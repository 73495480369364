import Image from 'next/image';
import { useContext, useEffect } from 'react';
import { LightBoxContext } from '../WrapperLightBox/WrapperLightBox';

const makePageScrollable = () => {
  const html = document.querySelector('html');
  if (html) {
    html.style.overflow = 'auto';
  }
};

const LightBox = () => {
  const { urlImage, setUrlImage, textAlt } = useContext(LightBoxContext);

  useEffect(() => {
    // Remove ability to scroll when lightbox is shown.
    const html = document.querySelector('html');
    if (html && urlImage !== '') {
      html.style.overflow = 'hidden';
    }
  }, [urlImage]);
  if (urlImage === '') {
    return null;
  }
  return (
    <div className="fixed right-0 top-0 z-40 h-full w-full">
      <div className="z-30 h-full w-full bg-black opacity-80 backdrop-filter" />
      <button
        className="fixed right-5 top-5 z-40 h-10 w-10 font-bold lg:right-50 lg:top-50"
        type="button"
        onClick={() => {
          makePageScrollable();
          setUrlImage('');
        }}
      >
        <span className="absolute block h-0.5 w-full origin-center rotate-45 bg-tt-white opacity-100 transition"></span>
        <span className="absolute block h-0.5 w-full origin-center -rotate-45 bg-tt-white opacity-100 transition"></span>
      </button>
      <div
        className="fixed left-0 top-0 flex h-full w-full items-center justify-center"
        onClick={() => {
          makePageScrollable();
          setUrlImage('');
        }}
      >
        <Image
          src={urlImage}
          alt={textAlt}
          fill
          className="z-40 h-3/4 w-3/4 object-contain"
        />
      </div>
    </div>
  );
};

export default LightBox;
