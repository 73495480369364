import ArrowRightSVG from 'public/images/arrow-right.svg';

export interface ArrowProps {
  children?: React.ReactNode;
  inverted?: boolean;
  disabled?: boolean;
  pointingLeft?: boolean;
}

export default function Arrow({
  children,
  inverted,
  disabled,
  pointingLeft,
}: ArrowProps) {
  return (
    <div
      className={`group relative pl-11 text-link-m font-light uppercase drop-shadow-sm hover:drop-shadow-none lg:pl-14 lg:text-link-d ${
        inverted ? 'text-tt-white' : 'text-secondary hover:text-primary'
      }
        ${disabled ? 'pointer-events-none text-secondary opacity-20' : ''}
      `}
    >
      <ArrowRightSVG
        className={`absolute left-0 h-full w-7 transition-transform ${
          pointingLeft
            ? `rotate-180 group-hover:-translate-x-2`
            : `group-hover:translate-x-2`
        } lg:w-10`}
      />
      <span className="block text-left">{children}</span>
    </div>
  );
}
