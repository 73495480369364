export default function Textarea({ register, label, id, error, ...props }) {
  return (
    <div className="mt-3 grid grid-cols-6 gap-x-2 gap-y-2 lg:gap-x-4">
      <label
        htmlFor={id}
        className="col-span-2 pt-1 text-base-m font-light lg:text-base-d"
      >
        {label}
      </label>
      <textarea
        className={`col-span-6 block w-full border-2 border-secondary bg-transparent p-2 focus:border-primary focus:outline-none focus:ring-0 focus-visible:outline-none lg:col-span-4 ${
          error ? 'border-red-500' : ''
        }`}
        {...props}
        {...register}
      />
      {error && (
        <span className="col-start-1 col-end-7 text-sm text-red-500 lg:col-start-3">
          {error.message}
        </span>
      )}
    </div>
  );
}
