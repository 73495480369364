import Link, { LinkProps } from 'next/link';
import Arrow from 'components/Misc/Arrow';

export interface ArrowLinkProps extends LinkProps {
  children?: React.ReactNode;
  className?: string;
  inverted?: boolean;
  pointingLeft?: boolean;
}

export default function ArrowLink({
  className,
  children,
  inverted,
  pointingLeft,
  ...props
}: ArrowLinkProps) {
  return (
    <Link {...props} className={`${className ? className : ''}`}>
      <Arrow inverted={inverted} pointingLeft={pointingLeft}>
        {children}
      </Arrow>
    </Link>
  );
}
