export default function ConfirmationPage({ webform, submission }) {
  const defaultMessage = (
    <>
      New submission added to <em>{webform.title}</em>.
    </>
  );

  return (
    <div>
      {webform.confirmation.message &&
      webform.confirmation.message.length > 0 ? (
        <div
          dangerouslySetInnerHTML={{ __html: webform.confirmation.message }}
        ></div>
      ) : (
        defaultMessage
      )}
    </div>
  );
}
