import * as React from 'react';
import { convertDateToISO } from 'nextjs-drupal-webform';
import WebformElementWrapper from './WebformElementWrapper';

export default function WebformDate({
  element,
  error,
  setValue,
  value = '',
  fieldProps,
}) {
  const onChangeHandler = async (e) => {
    setValue(e.target.value);
  };

  return (
    <WebformElementWrapper
      labelFor={element['#key']}
      label={element['#title']}
      isRequired={element['#required']}
      access={element['#access']}
      settings={null}
      error={error}
    >
      <input
        className="col-span-6 block w-full border-0 border-b-2 border-secondary bg-transparent focus:border-primary focus:ring-0 focus-visible:outline-none lg:col-span-4"
        onChange={(e) => onChangeHandler(e)}
        type={element['#type']}
        name={element['#name']}
        disabled={element['#disabled']}
        hidden={!element['#access']}
        required={element['#required']}
        readOnly={element['#readonly']}
        value={value}
        {...fieldProps}
        id={element['#id']}
        min={
          element['#date_date_min']
            ? convertDateToISO(element['#date_date_min'])
            : null
        }
        max={
          element['#date_date_max']
            ? convertDateToISO(element['#date_date_max'])
            : null
        }
      />
    </WebformElementWrapper>
  );
}
