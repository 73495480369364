import Glide, {
  Controls,
  Breakpoints,
  Swipe,
} from '@glidejs/glide/dist/glide.modular.esm';
import '@glidejs/glide/dist/css/glide.core.min.css';
import { useRef, useEffect } from 'react';
import TeaserCard from './TeaserCard';
import ArrowRight from '../../../public/images/arrow-right.svg';

export default function InformationTeaserSlider({ cards }) {
  const ref = useRef();

  useEffect(() => {
    const gallery_settings = {
      type: 'carousel',
      startAt: 0,
      perView: 3,
      gap: '16',
      focusAt: 'center',
      breakpoints: {
        920: {
          perView: 2,
        },
        640: {
          gap: '0',
          perView: 1,
        },
      },
    };

    new Glide(ref.current, gallery_settings).mount({
      Controls,
      Breakpoints,
      Swipe,
    });
  }, [ref]);

  return (
    <div
      ref={ref}
      data-paragraph-type="InformationTeaserSlider"
      className="glide mx-auto max-w-5xl px-14 lg:px-8"
    >
      <div className="glide__track" data-glide-el="track">
        <ul className="glide__slides">
          {cards?.length ? (
            cards.map((card) => (
              <li key={card.id} className="glide__slide">
                <TeaserCard card={card} />
              </li>
            ))
          ) : (
            <p className="py-4">No images found</p>
          )}
        </ul>
      </div>

      <div className="glide__arrows" data-glide-el="controls">
        <button
          className="glide__arrow--left absolute inset-y-1/2 left-2 block lg:-left-5"
          data-glide-dir="<"
        >
          <ArrowRight
            width={39}
            height={19}
            className="rotate-180 fill-secondary"
          ></ArrowRight>
        </button>
        <button
          className="glide__arrow glide__arrow--right absolute inset-y-1/2 right-2 block lg:-right-5"
          data-glide-dir=">"
        >
          <ArrowRight
            width={39}
            height={19}
            className="rotate-0 fill-secondary"
          ></ArrowRight>
        </button>
      </div>
    </div>
  );
}
