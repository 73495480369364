import ArrowLink from 'components/Misc/ArrowLink';
import useWindowSize from 'hooks/useWindowSize';
import Image from 'next/image';

const layoutData = [
  'col-start-1 col-end-13 row-start-1 row-end-2 h-[11.5rem] lg:col-start-1 lg:col-end-3 lg:row-start-1 lg:row-end-3 lg:h-80',
  'col-start-1 col-end-7 row-start-2 row-end-3 h-[11.5rem] lg:col-start-3 lg:col-end-5 lg:row-start-1 lg:row-end-3 lg:h-80',
  'col-start-7 col-end-13 row-start-2 row-end-3 h-[11.5rem] lg:col-start-5 lg:col-end-6 lg:row-start-1 lg:row-end-5 lg:h-160',
  'col-start-1 col-end-13 row-start-3 row-end-4 h-[11.5rem] lg:col-start-1 lg:col-end-5 lg:row-start-3 lg:row-end-5 lg:h-80',
  'col-start-1 col-end-7 row-start-4 row-end-5 h-[11.5rem]  lg:col-start-1 lg:col-end-4 lg:row-start-5 lg:row-end-9 lg:h-160',
  'col-start-7 col-end-13 row-start-4 row-end-5 h-[11.5rem] lg:col-start-4 lg:col-end-6 lg:row-start-5 lg:row-end-7 lg:h-80',
  'col-start-1 col-end-13 row-start-5 row-end-6 h-[11.5rem] lg:col-start-4 lg:col-end-6 lg:row-start-7 lg:row-end-9 lg:h-80',
  'col-start-1 col-end-7 row-start-6 row-end-7 h-[11.5rem] lg:col-start-1 lg:col-end-3 lg:row-start-9 lg:row-end-11 lg:h-80',
  'col-start-7 col-end-13 row-start-6 row-end-7 h-[11.5rem] lg:col-start-3 lg:col-end-6 lg:row-start-9 lg:row-end-11 lg:h-80',
];

const imageStyle = [
  'focal_point_gallery_image_320x320',
  'focal_point_gallery_image_320x320',
  'focal_point_gallery_image_150x660',
  'focal_point_gallery_image_660x320',
  'focal_point_gallery_image_490x650',
  'focal_point_gallery_image_320x320',
  'focal_point_gallery_image_320x320',
  'focal_point_gallery_image_320x320',
  'focal_point_gallery_image_660x320',
];

export default function ImageWallItem({ imageWallItem, index }) {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 1024;
  const isSquareMedia =
    imageStyle[index] === 'focal_point_gallery_image_320x320';

  if (isMobile) {
    return (
      <figure className={`relative ${layoutData[index]}`}>
        {imageWallItem?.field_image && (
          <Image
            fill
            quality={100}
            sizes="(max-width: 1024px) 100vw, 800px"
            src={
              imageWallItem?.field_image?.field_media_image?.image_style_uri?.[
                imageStyle[index] ||
                  imageWallItem?.field_image?.field_media_image?.uri?.url
              ]
            }
            alt={
              imageWallItem.field_image?.field_media_image?.resourceIdObjMeta
                ?.alt
            }
            className="object-cover brightness-90"
          />
        )}
        {imageWallItem?.field_link?.full_url && (
          <div className="overflow-wrap-anywhere absolute bottom-2 left-4 hyphens-auto lg:break-normal">
            <ArrowLink
              inverted
              href={imageWallItem?.field_link?.full_url ?? ''}
            >
              <span className="text-lg lg:text-xl">
                {imageWallItem?.field_link?.title}
              </span>
            </ArrowLink>
          </div>
        )}
      </figure>
    );
  }

  return (
    <figure className={`relative ${layoutData[index]}`}>
      {imageWallItem?.field_video_youtube_id ||
      imageWallItem?.field_video_url ? (
        <>
          {imageWallItem?.field_video_youtube_id ? (
            <div className="relative h-full w-full overflow-hidden">
              <iframe
                className={`pointer-events-none absolute left-1/2 top-1/2 aspect-video -translate-x-1/2 ${
                  isSquareMedia
                    ? // Scaling and translating square content is a hack to hide the youtube controls.
                      `h-[120%] -translate-y-[57%]`
                    : `h-full -translate-y-1/2 scale-[1.6]`
                }`}
                src={`https://www.youtube.com/embed/${imageWallItem.field_video_youtube_id}?controls=0&mute=1&autoplay=1&showinfo=0&rel=0&modestbranding=1&disablekb=1&loop=1&playlist=${imageWallItem.field_video_youtube_id}`}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              />
            </div>
          ) : (
            <video
              autoPlay
              loop
              muted
              playsInline
              className="absolute h-full w-full object-cover"
            >
              <source src={imageWallItem?.field_video_url} type="video/mp4" />
            </video>
          )}
        </>
      ) : (
        <Image
          fill
          quality={100}
          sizes="(max-width: 1024px) 100vw, 800px"
          src={
            imageWallItem?.field_image?.field_media_image?.image_style_uri?.[
              imageStyle[index] ||
                imageWallItem?.field_image?.field_media_image?.uri?.url
            ]
          }
          alt={
            imageWallItem.field_image?.field_media_image?.resourceIdObjMeta?.alt
          }
          className="object-cover brightness-90"
        />
      )}
      {imageWallItem?.field_link?.full_url && (
        <div className="overflow-wrap-anywhere absolute bottom-2 left-4 hyphens-auto lg:break-normal">
          <ArrowLink inverted href={imageWallItem?.field_link?.full_url ?? ''}>
            <span className="text-lg lg:text-xl">
              {imageWallItem?.field_link?.title}
            </span>
          </ArrowLink>
        </div>
      )}
    </figure>
  );
}
