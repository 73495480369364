import P11TextIntroduction from 'components/paragraphs/P11TextIntroduction';
import P12HalfTextHalfImage from 'components/paragraphs/P12HalfTextHalfImage';
import P13Accordion from 'components/paragraphs/P13Accordion';
import P1Header from 'components/paragraphs/P1Header';
import P2Text from 'components/paragraphs/P2Text';
import P3ImageWall from 'components/paragraphs/P3ImageWall';
import P4Counter from 'components/paragraphs/P4Counter';
import P5TextAudio from 'components/paragraphs/P5TextAudio';
import P5TextImage from 'components/paragraphs/P5TextImage';
import P5TextImageGallery from 'components/paragraphs/P5TextImageGallery';
import P7FullScreenImage from 'components/paragraphs/P7FullScreenMedia';
import P99Space from 'components/paragraphs/P99Space';
import P9InformationTeaser from 'components/paragraphs/P9InformationTeaser';
import P10NewsTeaser from 'components/paragraphs/P10NewsTeaser';
import P14ProductCards from 'components/paragraphs/P14ProductCards';
import P15Webform from 'components/paragraphs/P15Webform';
import P16Blocks from 'components/paragraphs/P16Blocks';
import P18PriceInfo from 'components/paragraphs/P18PriceInfo';
import P1HeaderSubPage from 'components/paragraphs/P1HeaderSubPage';
import P11TextIntroductionTwoColumn from 'components/paragraphs/P11TextIntroductionTwoColumn';
import P18PriceInfoWithoutImage from '../P18PriceInfoWithoutImage';
import P20InstagramWall from '../P20InstagramWall';
import P21Disruptor from '../P21Disruptor';

export const paragraphTypes = {
  'paragraph--header': P1Header,
  'paragraph--header_subpage': P1HeaderSubPage,
  'paragraph--text': P2Text,
  'paragraph--image_wall_reusable': P3ImageWall,
  'paragraph--counter': P4Counter,
  'paragraph--text_audio': P5TextAudio,
  'paragraph--text_image_gallery': P5TextImageGallery,
  'paragraph--textimage': P5TextImage,
  'paragraph--full_screen_media': P7FullScreenImage,
  'paragraph--information_teaser': P9InformationTeaser,
  'paragraph--news_teaser_reusable': P10NewsTeaser,
  'paragraph--text_introduction': P11TextIntroduction,
  'paragraph--half_text_half_image_reusable': P12HalfTextHalfImage,
  'paragraph--accordion': P13Accordion,
  'paragraph--product_cards': P14ProductCards,
  'paragraph--form': P15Webform,
  'paragraph--blocks': P16Blocks,
  'paragraph--space': P99Space,
  'paragraph--price_info_reusable': P18PriceInfo,
  'paragraph--price_info_wo_image_reusable': P18PriceInfoWithoutImage,
  'paragraph--text_introduction_2_column': P11TextIntroductionTwoColumn,
  'paragraph--instagram_wall': P20InstagramWall,
  'paragraph--disruptor': P21Disruptor,
};
