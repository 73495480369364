export default function Select({
  id,
  label,
  options,
  error,
  register,
  ...props
}) {
  return (
    <div className="grid grid-cols-6 gap-x-2 lg:gap-x-4 gap-y-2 mt-2">
      <label
        htmlFor={id}
        className="text-base-m lg:text-base-d font-light col-span-2 pt-1"
      >
        {label}
      </label>
      <select
        id={id}
        name={id}
        className={`col-span-6 lg:col-span-4 p-2 block w-full bg-transparent border-2 border-secondary focus:outline-none focus:ring-0 focus:border-primary ${
          error ? 'border-red-500' : ''
        }`}
        {...props}
        {...register}
      >
        <option value="">- Auswählen -</option>
        {options.map((option) => (
          <option value={option.value} key={option.value}>
            {option.label}
          </option>
        ))}
      </select>

      {error && (
        <span className="col-start-1 lg:col-start-3 col-end-7 text-sm text-red-500">
          {error.message}
        </span>
      )}
    </div>
  );
}
