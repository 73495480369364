export default function P18PrinceInfoTable({ paragraph }) {
  paragraph = paragraph?.field_price_info_without_image;

  const theme = paragraph?.field_theme ?? '';

  const tableHeader = paragraph?.field_table_header;
  const rows = paragraph?.field_rows;

  return (
    <div
      data-paragraph-type="P18PriceInfoTable"
      className="max-w mx-auto max-w-5xl px-14 lg:px-0"
    >
      {(paragraph?.field_title || paragraph?.field_byline) && (
        <div className="p-14 text-secondary">
          <h2 className="mb-0 font-serif text-h2-m lg:text-h2-d">
            {paragraph?.field_title}
          </h2>
          <h3 className="mb-0 text-h3-m uppercase lg:text-h3-d">
            {paragraph?.field_byline}
          </h3>
        </div>
      )}
      <div className={`flex gap-x-14 bg-primary ${theme}`}>
        <div className={`flex flex-col p-10 lg:hidden`}>
          <div className={`pb-6 text-xl uppercase`}>
            {tableHeader?.field_row_title}
          </div>
          <div className="text-base-m">
            {rows?.map((row) => {
              return (
                <>
                  <div className="pb-6 text-base-m font-bold">
                    {row.field_row_title}
                  </div>
                  {row.field_row.map((column, index) => (
                    <>
                      <div className={`font-bold text-white`}>
                        {tableHeader?.field_row[index]}
                      </div>
                      <div className={`pb-6 text-white`}>{column}</div>
                    </>
                  ))}
                  <div className={`mb-6 w-2/3 border-b-2`} />
                </>
              );
            })}
          </div>
        </div>
        <div className={`hidden grid-cols-5 gap-4 p-10 lg:grid`}>
          <div className={`col-span-2 text-xl uppercase`}>
            {tableHeader?.field_row_title}
          </div>
          <div className={`text-base-d font-bold text-white`}>
            {tableHeader?.field_row[0]}
          </div>
          <div className={`text-base-d font-bold text-white`}>
            {tableHeader?.field_row[1]}
          </div>
          <div className={`text-base-d font-bold text-white`}>
            {tableHeader?.field_row[2]}
          </div>
          {rows.map((row) => {
            return (
              <>
                <div className={`col-span-2 text-base-d font-bold`}>
                  {row?.field_row_title}
                </div>
                {row?.field_row?.map((column, index) => (
                  <div
                    key={index}
                    className={`text-base-d font-thin text-white`}
                  >
                    {column}
                  </div>
                ))}
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}
