import React, { useState } from 'react';
import CounterItem from './CounterItem';
import { Waypoint } from 'react-waypoint';

export default function P4Counter({ paragraph }) {
  const [viewPortEntered, setViewPortEntered] = useState(false);

  const onViewPortEntered = () => {
    setViewPortEntered(true);
  };

  return (
    <Waypoint onEnter={onViewPortEntered}>
      <div
        data-paragraph-type="P4Counter"
        className="max-w-4xl mx-auto px-6 sm:px-6 lg:px-8"
      >
        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-20 text-center drop-shadow">
          {viewPortEntered &&
            paragraph.field_counter_items?.map((counterItem, index) => (
              <CounterItem
                counterItem={counterItem}
                index={index}
                key={index}
              />
            ))}
        </div>
      </div>
    </Waypoint>
  );
}
