import { useEffect } from 'react';

export default function PopUp({ show, setShow, children }) {
  useEffect(() => {
    // Remove ability to scroll when popup is shown.
    const html = document.querySelector('html');
    if (!html) {
      return;
    }
    html.style.overflow = show ? 'hidden' : 'auto';
    return () => {
      html.style.overflow = 'auto';
    };
  }, [show]);

  if (!show) {
    return null;
  }

  return (
    <>
      <div className="fixed left-0 right-0 top-0 bottom-0 z-50 backdrop-blur-md backdrop-filter"></div>
      <div className="fixed top-1/2 left-1/2 z-50 w-full max-w-screen-lg -translate-x-1/2 -translate-y-1/2 transform">
        <div className="m-2 max-h-[calc(100vh-1rem)] overflow-y-auto bg-secondary text-white">
          <button
            className="t-0 fixed mt-5 ml-5 h-9 w-9 font-bold md:mt-10 md:ml-10"
            type="button"
            onClick={() => {
              setShow(false);
            }}
          >
            <span className="absolute left-0 top-0 mt-4 block h-0.5 w-full origin-center rotate-45 bg-tt-white opacity-100 transition"></span>
            <span className="absolute left-0 top-0 mt-4 block h-0.5 w-full origin-center -rotate-45 bg-tt-white opacity-100 transition"></span>
          </button>
          <div className="px-20 py-20 md:px-40 md:pt-25">{children}</div>
        </div>
      </div>
    </>
  );
}
