import Image from 'next/image';
import React, { useEffect, useRef } from 'react';
import Glide, { Controls } from '@glidejs/glide/dist/glide.modular.esm';

import '@glidejs/glide/dist/css/glide.core.min.css';
import { absoluteUrl } from 'lib/utils';
import ArrowLink from 'components/Misc/ArrowLink';

export default function P5TextImageGallery({ paragraph }) {
  let reverse_class = '';
  if (paragraph.field_reverse == true) {
    reverse_class = 'flex-row-reverse';
  }

  const gallery_images = paragraph.field_images;

  const sliderRef = useRef();
  useEffect(() => {
    const gallery_settings = {
      type: 'carousel',
      startAt: 0,
      perView: 1,
      focusAt: 'center',
    };

    const slider = new Glide(sliderRef.current, gallery_settings);
    slider.mount({ Controls });
    return () => slider.destroy();
  }, []);

  return (
    <div
      data-paragraph-type="P5TextImageGallery"
      className="mx-auto max-w-4xl px-0 lg:px-8"
    >
      <div
        className={`block flex-none grid-cols-2 gap-8 lg:grid ${reverse_class}`}
      >
        <div className="relative basis-0 lg:basis-1/2">
          {paragraph.field_side_image?.field_media_image && (
            <Image
              src={
                paragraph?.field_side_image?.field_media_image?.image_style_uri
                  ?.focal_point_side_image_300x400
              }
              alt=""
              className={
                'relative h-[11.5rem] w-full object-cover lg:h-[25.5rem] lg:w-[20.25rem]'
              }
              width={324}
              height={409}
            />
          )}
        </div>
        <div className="basis-0 lg:basis-1/2">
          <div className="mt-17.5 px-14 text-secondary lg:mt-0 lg:px-0">
            <div
              className="mb-10 break-words text-base-m lg:break-normal lg:text-base-d"
              dangerouslySetInnerHTML={{
                __html: paragraph?.field_body_formatted?.processed,
              }}
            />
          </div>
          <div
            ref={sliderRef}
            className="text-image-gallery glide px-14 lg:px-0"
          >
            <div className="glide__arrows" data-glide-el="controls">
              <button
                className="glide__arrow--left relative"
                data-glide-dir="<"
              >
                <Image
                  src={'/images/btn-arrow-left-blue.png'}
                  width={31}
                  height={27}
                  alt="Arrow Left"
                />
              </button>
              <button
                className="glide__arrow glide__arrow--right relative"
                data-glide-dir=">"
              >
                <Image
                  src={'/images/btn-arrow-right-blue.png'}
                  width={31}
                  height={27}
                  alt="Arrow Right"
                />
              </button>
            </div>
            <div className="glide__track mt-10 lg:mt-24" data-glide-el="track">
              <ul className="glide__slides">
                {gallery_images?.length ? (
                  gallery_images.map((gallery_image) => {
                    if (gallery_image?.field_media_image?.uri?.url) {
                      return (
                        <li
                          key={gallery_image?.field_media_image?.id}
                          className="glide__slide"
                        >
                          <Image
                            src={
                              gallery_image?.field_media_image?.image_style_uri
                                ?.focal_point_gallery_image_400x600
                            }
                            width={409}
                            height={324}
                            className="object-cover"
                            priority={true}
                            alt={''}
                          />
                        </li>
                      );
                    }
                  })
                ) : (
                  <p className="py-4">No images found</p>
                )}
              </ul>
            </div>
          </div>
          <div>
            {paragraph?.field_link?.full_url && (
              <div className="mt-10 px-14 lg:px-0">
                <ArrowLink href={paragraph.field_link.full_url}>
                  {paragraph?.field_link?.title}
                </ArrowLink>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
