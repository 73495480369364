interface CurvedTitleProps {
  children: React.ReactNode;
  className?: string;
  isSpan?: boolean;
}

export default function CurvedTitle({
  children,
  className,
  isSpan = false,
}: CurvedTitleProps) {
  if (isSpan) {
    return (
      <span
        className={`m-0 block hyphens-auto font-serif text-curved-m font-thin lg:text-curved-d ${
          className ?? ''
        }`}
      >
        {children}
      </span>
    );
  }
  return (
    <h2
      className={`m-0 font-serif text-curved-m font-thin lg:text-curved-d ${
        className ?? ''
      }`}
    >
      {children}
    </h2>
  );
}
