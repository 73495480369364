import Image from 'next/image';
import Link from 'next/link';

interface InstagramPostProps {
  link: string;
  title: string;
  image: string;
}

export default function InstagramPost({
  link,
  title,
  image,
}: InstagramPostProps) {
  return (
    <div className="group relative overflow-hidden bg-primary">
      <Link href={link}>
        <Image
          src={image}
          alt={title}
          fill
          className="object-cover"
          sizes="(max-width: 640px) 100vw,(max-width: 1024px) 50vw, 320px"
        />
        <div className="absolute left-0 top-0 flex h-full w-full flex-col justify-end bg-black bg-opacity-0 opacity-0 transition group-hover:bg-opacity-30 group-hover:opacity-100">
          <div className="p-2 text-tt-white">{title}</div>
        </div>
      </Link>
    </div>
  );
}
