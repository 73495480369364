import Image from 'next/image';
import { absoluteUrl } from 'lib/utils';
import useWindowSize from 'hooks/useWindowSize';
import styles from './P7FullScreenMedia.module.css';

export default function P7FullScreenMedia({ paragraph }) {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 1024;
  return (
    <div data-paragraph-type="P7FullScreenMedia">
      <div className="relative flex h-[20rem] w-full items-center justify-center overflow-hidden lg:h-[42rem]">
        {paragraph?.field_image ? (
          <Image
            src={absoluteUrl(paragraph.field_image.field_media_image?.uri?.url)}
            alt={paragraph.field_image.field_media_image.resourceIdObjMeta?.alt}
            fill
            sizes="100vw"
            quality={90}
            className="object-cover"
          />
        ) : (
          <>
            {paragraph?.field_video_youtube_id && !isMobile && (
              <iframe
                className={`pointer-events-none absolute left-1/2 top-1/2 h-[102vh] w-screen -translate-x-1/2 -translate-y-1/2 border-none ${styles['fullscreen-iframe-video']}`}
                src={`https://www.youtube.com/embed/${paragraph.field_video_youtube_id}?controls=0&mute=1&autoplay=1&showinfo=0&rel=0&modestbranding=1&disablekb=1&loop=1&playlist=${paragraph.field_video_youtube_id}`}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            )}

            {paragraph?.field_video_mobile && isMobile && (
              <video
                autoPlay
                loop
                muted
                playsInline
                className="absolute h-screen w-auto min-w-full max-w-none object-cover"
              >
                <source
                  src={absoluteUrl(
                    paragraph.field_video_mobile.field_media_file?.uri?.url
                  )}
                  type="video/mp4"
                />
              </video>
            )}

            {paragraph?.field_video_url &&
              !paragraph?.field_video_youtube_id &&
              !paragraph?.field_video_mobile && (
                <video
                  autoPlay
                  loop
                  muted
                  playsInline
                  className="absolute h-screen w-auto min-w-full max-w-none object-cover"
                >
                  <source src={paragraph.field_video_url} type="video/mp4" />
                </video>
              )}
          </>
        )}
      </div>
    </div>
  );
}
