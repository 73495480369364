import useWindowSize from 'hooks/useWindowSize';
import { absoluteUrl } from 'lib/utils';
import Image from 'next/image';
import { useRef } from 'react';
import styles from './P1Header.module.css';

export default function P1Header({ paragraph }) {
  const anchorRef = useRef(null);
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 1024;
  const handleClick = (event) => {
    event.preventDefault();
    anchorRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div
      data-paragraph-type="P1Header"
      className="relative flex h-screen items-center justify-center overflow-hidden"
    >
      <div className="relative z-10 mx-auto h-screen w-full max-w-7xl px-6 sm:px-6 lg:px-8">
        <div className="absolute inset-x-0 bottom-1/2 mx-auto grid h-[4.375rem] max-w-max auto-rows-auto text-subpage-title-m font-light uppercase text-tt-white opacity-60 lg:bottom-40 lg:h-[8.25rem] lg:w-[44rem] lg:text-landingpage-title-d">
          <p className="mb-0">{paragraph?.field_title_top_line}</p>
          <div className="flex">
            <p className="mb-0 mt-[-10px] pl-6 lg:pl-20">
              {paragraph?.field_title_bottom_line}
            </p>
            <div className="hidden w-[8rem] pl-2 lg:block">
              <p className="mt-[-0.25rem] text-lg font-bold">
                {paragraph?.field_subtitle}
              </p>
            </div>
          </div>
        </div>
        <div className="absolute bottom-10 flex w-full items-center justify-center">
          <a onClick={handleClick} href="#">
            <svg
              className="h-10 w-10 text-tt-white opacity-60"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
            </svg>
          </a>
        </div>
      </div>

      {paragraph?.field_image ? (
        <Image
          src={absoluteUrl(paragraph.field_image.field_media_image?.uri?.url)}
          alt={paragraph.field_image.field_media_image.resourceIdObjMeta?.alt}
          fill
          sizes="(max-width: 1024px) 100vh, 100vw" // use vh on mobile to avoid image getting blurry
          quality={90}
          className="h-screen object-cover"
        />
      ) : (
        <>
          {paragraph?.field_video_youtube_id && !isMobile && (
            <iframe
              className={`pointer-events-none absolute left-1/2 top-1/2 h-[102vh] w-screen -translate-x-1/2 -translate-y-1/2 border-none ${styles['fullscreen-iframe-video']}`}
              src={`https://www.youtube.com/embed/${paragraph.field_video_youtube_id}?controls=0&mute=1&autoplay=1&showinfo=0&rel=0&modestbranding=1&disablekb=1&loop=1&playlist=${paragraph.field_video_youtube_id}`}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          )}

          {paragraph?.field_video_mobile && isMobile && (
            <video
              autoPlay
              loop
              muted
              playsInline
              className="absolute h-screen w-auto min-w-full max-w-none object-cover"
            >
              <source
                src={absoluteUrl(
                  paragraph.field_video_mobile.field_media_file?.uri?.url
                )}
                type="video/mp4"
              />
            </video>
          )}

          {paragraph?.field_video_url &&
            !paragraph?.field_video_youtube_id &&
            !paragraph?.field_video_mobile && (
              <video
                autoPlay
                loop
                muted
                playsInline
                className="absolute h-screen w-auto min-w-full max-w-none object-cover"
              >
                <source src={paragraph.field_video_url} type="video/mp4" />
              </video>
            )}
        </>
      )}

      <div ref={anchorRef} className="absolute bottom-0"></div>
    </div>
  );
}
