import * as React from 'react';
import { WebformWrapperProps } from 'nextjs-drupal-webform';
import classNames from 'classnames';

export default function WebformElementWrapper({
  children,
  label,
  labelProps = {},
  labelFor,
  isRequired,
  description,
  descriptionDisplay,
  descriptionProps = {},
  labelDisplay = 'before',
  error,
  access,
  ...props
}: WebformWrapperProps) {
  const css = `
.required-field-custom:after {
  content: ' *';
}
.visually-hidden {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
  width: 1px;
  word-wrap: normal;
}
        `;
  const labelClasses = classNames(labelProps['className'] as string, {
    'required-field-custom': isRequired,
    'visually-hidden': labelDisplay === 'invisible',
  });

  const descriptionClasses = classNames(descriptionProps['className'], {
    'visually-hidden': descriptionDisplay === 'invisible',
  });

  const labelElement = (
    <label
      {...labelProps}
      className={`col-span-2 pt-1 text-base-m font-light lg:text-base-d ${labelClasses}`}
      htmlFor={labelFor}
    >
      {label}
    </label>
  );
  return access ? (
    <div
      className="mt-2 grid grid-cols-6 gap-x-2 gap-y-2 lg:gap-x-4"
      {...props}
    >
      <style suppressHydrationWarning>{css}</style>
      {!['after', 'none'].includes(labelDisplay) && labelElement}
      {descriptionDisplay === 'before' && description && (
        <div {...descriptionProps}>{description}</div>
      )}
      {children}
      {(descriptionDisplay === 'after' || descriptionDisplay === 'invisible') &&
        description && (
          <span
            {...descriptionProps}
            className={`col-start-1 col-end-7 text-sm text-gray-500 lg:col-start-3 ${descriptionClasses}`}
          >
            {description}
          </span>
        )}
      {labelDisplay === 'after' && labelElement}
      {error && (
        <span className="col-start-1 col-end-7 text-sm text-red-500 lg:col-start-3">
          {error}
        </span>
      )}
    </div>
  ) : null;
}
