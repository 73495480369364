import { absoluteUrl } from 'lib/utils';
import Image from 'next/image';
import styles from './P11TextIntroductionTwoColumn.module.css';

export default function P11TextIntroductionTwoColumn({ paragraph }) {
  return (
    <div
      data-paragraph-type="P11TextIntroductionTwoColumn"
      className="flex flex-col gap-x-4 gap-y-14 xl:flex-row"
    >
      <div className="relative ml-auto flex max-w-6xl basis-1/2 flex-col pt-16">
        <div
          className={
            'absolute top-0 right-0 h-full w-screen bg-secondary md:h-2/3 lg:h-3/4'
          }
        ></div>
        <div className="relative ml-auto mb-4 w-full max-w-4xl px-14 lg:mb-16 lg:pr-20">
          <h2
            className={
              'mb-2 break-normal font-serif text-byline-m font-light text-tt-white lg:text-intro-byline-d'
            }
          >
            {paragraph?.field_first_column_title}
          </h2>
          <h3 className="mb-0 text-h3-m uppercase text-tt-white lg:text-intro-title-d">
            {paragraph?.field_first_column_byline}
          </h3>
        </div>
        <div className="relative ml-auto mb-10 w-full max-w-4xl px-14 lg:pr-20">
          {paragraph?.field_first_column_text?.processed && (
            <div
              className={
                'prose text-base-m font-light text-tt-white prose-a:text-tt-white prose-strong:text-tt-white lg:text-base-d'
              }
              dangerouslySetInnerHTML={{
                __html: paragraph?.field_first_column_text?.processed,
              }}
            />
          )}
        </div>
        <div className={`${styles.imageContainer} ml-auto`}>
          {paragraph?.field_first_column_image && (
            <Image
              src={absoluteUrl(
                paragraph?.field_first_column_image?.field_media_image?.uri?.url
              )}
              alt={
                paragraph.field_first_column_image?.field_media_image
                  ?.resourceIdObjMeta?.alt
              }
              fill
              sizes="(max-width: 1024px) 100vw, 50vw"
              className={`${styles.image} ml-auto`}
            />
          )}
        </div>
      </div>

      <div className="relative mr-auto flex max-w-6xl basis-1/2 flex-col pt-16">
        <div
          className={
            'absolute top-0 left-0 h-full w-screen bg-secondary md:h-2/3 lg:h-3/4'
          }
        ></div>
        <div className="relative mb-4 w-full max-w-4xl px-14 lg:mb-16 lg:pl-20">
          <h2
            className={
              'mb-2 break-normal font-serif text-byline-m font-light text-tt-white lg:text-intro-byline-d'
            }
          >
            {paragraph?.field_second_column_title}
          </h2>
          <h3 className="mb-0 text-h3-m uppercase text-tt-white lg:text-intro-title-d">
            {paragraph?.field_second_column_byline}
          </h3>
        </div>
        <div className="relative mb-10 w-full max-w-4xl px-14 lg:pl-20">
          {paragraph?.field_second_column_text?.processed && (
            <div
              className={
                'prose text-base-m font-light text-tt-white prose-a:text-tt-white prose-strong:text-tt-white lg:text-base-d'
              }
              dangerouslySetInnerHTML={{
                __html: paragraph?.field_second_column_text?.processed,
              }}
            />
          )}
        </div>

        <div className={styles.imageContainer}>
          {paragraph?.field_second_column_image && (
            <Image
              src={absoluteUrl(
                paragraph?.field_second_column_image?.field_media_image?.uri
                  ?.url
              )}
              alt={
                paragraph.field_second_column_image?.field_media_image
                  ?.resourceIdObjMeta?.alt
              }
              fill
              className={styles.image}
            />
          )}
        </div>
      </div>
    </div>
  );
}
