import Image from 'next/image';
import { absoluteUrl } from 'lib/utils';
import ArrowLink from 'components/Misc/ArrowLink';

export default function P5TextAudio({ paragraph }) {
  let alignSideImageWithBottom = paragraph.field_side_video
    ? 'bottom-0 lg:bottom-24'
    : 'top-0';
  let alignSideVideoWithBottom = paragraph.field_side_image
    ? 'bottom-0 lg:bottom-24'
    : 'top-0';

  return (
    <div
      data-paragraph-type="P5TextAudio"
      className="mx-auto max-w-4xl px-0 lg:px-8"
    >
      <div className={`flex-none gap-0 lg:flex lg:gap-[6.25rem]`}>
        <div className="relative mb-17.5 basis-0 lg:mb-0 lg:basis-1/2">
          {paragraph.field_side_image && (
            <Image
              src={
                paragraph?.field_side_image?.field_media_image?.image_style_uri
                  ?.focal_point_side_image_300x400
              }
              alt=""
              className={`${alignSideImageWithBottom} relative h-[11.5rem] w-full object-cover lg:absolute lg:h-[25.5rem] lg:w-[20.25rem]`}
              width={324}
              height={409}
            />
          )}
          <div
            className={`${alignSideVideoWithBottom} relative w-full lg:absolute`}
          >
            {paragraph.field_side_video && !paragraph.field_side_image && (
              <video
                autoPlay
                loop
                muted
                playsInline
                className="mb-[4.375rem] h-auto w-full lg:mb-0"
              >
                <source src={paragraph.field_side_video} type="video/mp4" />
              </video>
            )}
          </div>
        </div>
        <div className="basis-0 lg:basis-1/2">
          <div className="px-14 text-secondary lg:px-0">
            <div
              className={`text-base-m font-light lg:text-base-d ${paragraph?.color_theme}`}
              dangerouslySetInnerHTML={{
                __html: paragraph?.field_body_formatted?.processed,
              }}
            />
          </div>

          <div className="my-[2rem] px-14 lg:px-0">
            {paragraph.field_audio && (
              <audio controls controlsList="nodownload noplaybackrate">
                <source
                  src={absoluteUrl(
                    paragraph.field_audio.field_media_audio_file.uri.url
                  )}
                  type="audio/mpeg"
                />
              </audio>
            )}

            <div className="mt-10 block lg:hidden">
              {paragraph?.field_link?.full_url && (
                <div>
                  <ArrowLink href={paragraph?.field_link?.full_url}>
                    {paragraph?.field_link?.title}
                  </ArrowLink>
                </div>
              )}
            </div>
          </div>

          <div className="mb-0 basis-0 lg:mb-10 lg:basis-1/2">
            {paragraph.field_bottom_image && (
              <Image
                src={
                  paragraph?.field_bottom_image?.field_media_image
                    ?.image_style_uri?.focal_point_top_bottom_image_400x300
                }
                alt=""
                className="h-[11.5rem] w-full object-cover lg:h-[20.25rem] lg:w-[25.5rem]"
                width={409}
                height={324}
              />
            )}
            {paragraph.field_bottom_video && !paragraph.field_bottom_image && (
              <video autoPlay loop muted playsInline className="h-auto w-full">
                <source src={paragraph.field_bottom_video} type="video/mp4" />
              </video>
            )}
          </div>
          <div className="hidden lg:block">
            {paragraph?.field_link?.full_url && (
              <div className="mt-4 px-14 lg:px-0">
                <ArrowLink href={paragraph?.field_link?.full_url}>
                  {paragraph?.field_link?.title}
                </ArrowLink>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
