import Link from 'next/link';

export default function Checkbox({
  id,
  label,
  dataProtection = false,
  error,
  register,
  ...props
}) {
  return (
    <div className="mt-2">
      <label className="flex cursor-pointer gap-x-2 text-base-m lg:gap-x-4 lg:text-base-d">
        <input
          type="checkbox"
          className="relative h-4 w-4 cursor-pointer border-0 text-tt-white before:absolute before:top-[2px] before:left-[2px] before:h-3 before:w-3 checked:bg-none checked:before:bg-primary focus:outline-none focus:ring-0 focus:ring-offset-0 lg:h-8 lg:w-8 before:lg:top-1 before:lg:left-1 before:lg:h-6 before:lg:w-6"
          {...props}
          {...register}
        />

        {dataProtection && (
          <span className="lg:pt-1">
            Ich bin mit den{' '}
            <Link href="/datenschutz" passHref>
              Datenschutzbestimmungen
            </Link>{' '}
            der Tamina Therme AG einverstanden.
          </span>
        )}
        <span className="font-normal lg:pt-1">{label}</span>
      </label>

      {error && (
        <span className="col-start-1 col-end-7 text-sm text-red-500 lg:col-start-3">
          {error.message}
        </span>
      )}
    </div>
  );
}
