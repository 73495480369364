import LightBoxImage from 'components/Misc/LightBox/LightBoxImage';
import styles from './P12HalfTextHalfImage.module.css';

export default function P12HalfTextHalfImage({ paragraph }) {
  paragraph =
    paragraph?.field_half_text_half_image?.field_reusable_paragraph
      ?.paragraphs == undefined
      ? paragraph?.field_half_text_half_image
      : paragraph?.field_half_text_half_image?.field_reusable_paragraph
          ?.paragraphs;

  let reverse_class = '';
  if (paragraph?.field_reverse == true) {
    reverse_class = 'flex-row-reverse';
  }
  return (
    <div className="mx-auto max-w-4xl px-0 lg:px-8">
      <div
        className={`flex-none gap-0 lg:flex lg:gap-[6.25rem] ${reverse_class}`}
      >
        <div className="flex basis-0 lg:basis-[44%]">
          <div className={styles.imageContainer}>
            {paragraph?.field_image?.field_media_image && (
              <LightBoxImage
                src={
                  paragraph?.field_image?.field_media_image?.image_style_uri
                    ?.focal_point_side_image_300x400
                }
                alt={
                  paragraph.field_image.field_media_image.resourceIdObjMeta?.alt
                }
                width={648}
                height={818}
                allowLightBox={paragraph?.field_image?.field_lightbox}
                className={styles.image}
                urlImageFullscreen={
                  paragraph?.field_image?.field_media_image?.uri?.url
                }
              />
            )}
          </div>
        </div>

        <div className="basis-0 hyphens-auto px-14 text-secondary [overflow-wrap:anywhere] lg:basis-[56%] lg:px-0">
          <div>
            {paragraph?.field_byline && (
              <div className="tt-curved">{paragraph.field_byline}</div>
            )}
            {paragraph?.field_title && (
              <div className="tt-subtitle">{paragraph.field_title}</div>
            )}
          </div>
          {paragraph?.field_body_formatted?.processed && (
            <div
              className="prose pt-4 text-base-m font-light lg:text-base-d"
              dangerouslySetInnerHTML={{
                __html: paragraph.field_body_formatted.processed,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
