import { isPublished } from 'lib/utils';
import { paragraphTypes } from './utils';

export default function Paragraph({ paragraph }) {
  const { field_publish_on, field_unpublish_on } = paragraph;
  if (!isPublished(field_publish_on, field_unpublish_on)) {
    return null;
  }

  const ParagraphInstance = paragraphTypes[paragraph.type];
  if (!ParagraphInstance) {
    return null;
  }

  return <ParagraphInstance paragraph={paragraph} />;
}
