import ArrowLink from 'components/Misc/ArrowLink';
import { absoluteUrl } from 'lib/utils';
import Image from 'next/image';
import Link from 'next/link';

export default function TeaserCard({ card }) {
  return (
    <div
      className={`relative h-96 w-full bg-secondary text-secondary lg:h-128`}
    >
      {card?.field_image?.field_media_image &&
        (card?.field_link?.full_url ? (
          <Link href={card?.field_link?.full_url ?? ''} passHref>
            <Image
              width={324}
              height={2}
              src={absoluteUrl(card.field_image.field_media_image.uri.url)}
              alt=""
              className="h-36 w-full object-cover lg:h-48"
            />
          </Link>
        ) : (
          <Image
            width={324}
            height={2}
            src={absoluteUrl(card.field_image.field_media_image.uri.url)}
            alt=""
            className="h-36 w-full object-cover lg:h-48"
          />
        ))}
      <div className="text-clip px-6 py-4 lg:px-8 lg:py-8">
        <h3 className="mb-4 line-clamp-2 text-h3-m font-light uppercase text-tt-white lg:text-link-d">
          {card?.field_link?.full_url ? (
            <Link href={card?.field_link?.full_url ?? ''}>
              {card.field_title}
            </Link>
          ) : (
            <div>{card.field_title}</div>
          )}
        </h3>
        <p
          className={`hyphens-auto text-base-m font-light text-tt-white ${
            card?.field_image?.field_media_image && 'line-clamp-5'
          } lg:text-base-d`}
        >
          {card.field_body}
        </p>
        {card?.field_link?.full_url && (
          <div className="absolute bottom-0 left-0 right-0 px-6 py-4 lg:px-8 lg:py-8">
            <ArrowLink inverted href={card?.field_link?.full_url}>
              {card.field_link.title}
            </ArrowLink>
          </div>
        )}
      </div>
    </div>
  );
}
