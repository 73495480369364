import WebformElementWrapper from './WebformElementWrapper';

export default function WebformTime({
  element,
  setValue,
  value = '',
  fieldProps,
  error,
}) {
  const onChangeHandler = async (e) => {
    setValue(e.target.value);
  };

  return (
    <WebformElementWrapper
      labelFor={element['#key']}
      label={element['#title']}
      isRequired={element['#required']}
      access={element['#access']}
      settings={null}
      error={error}
    >
      <input
        className={`col-span-6 block w-full border-0 border-b-2 border-secondary bg-transparent focus:border-primary focus:ring-0 focus-visible:outline-none lg:col-span-4 ${
          error ? 'border-red-500' : ''
        }`}
        type="time"
        name={element['#name']}
        onChange={(e) => onChangeHandler(e)}
        disabled={element['#disabled']}
        hidden={!element['#access']}
        required={element['#required']}
        readOnly={element['#readonly']}
        value={value}
        {...fieldProps}
        id={element['#id']}
        min={element['#date_time_min'] ?? null}
        max={element['#date_time_max'] ?? null}
      />
    </WebformElementWrapper>
  );
}
