import Image from 'next/image';
import { useContext } from 'react';
import { LightBoxContext } from '../WrapperLightBox/WrapperLightBox';
interface LightBoxImageProps {
  src: string;
  alt: string;
  className: string;
  width: number;
  height: number;
  style?: any;
  urlImageFullscreen: string;
  allowLightBox: boolean;
}

const setUrlImageLightBox = (urlImage, setUrlImage, textAlt, setTextAlt) => {
  setUrlImage(urlImage);
  setTextAlt(textAlt);
};

const LightBoxImage = ({
  src,
  alt,
  className,
  width,
  height,
  style,
  urlImageFullscreen,
  allowLightBox,
}: LightBoxImageProps) => {
  const { setUrlImage, setTextAlt } = useContext(LightBoxContext);
  return (
    <div>
      <Image
        src={src}
        alt={alt}
        style={style}
        className={`${className} ${allowLightBox && 'cursor-pointer'}`}
        width={width}
        height={height}
        {...(allowLightBox && {
          onClick: () =>
            setUrlImageLightBox(
              urlImageFullscreen,
              setUrlImage,
              alt,
              setTextAlt
            ),
        })}
      />
    </div>
  );
};

export default LightBoxImage;
