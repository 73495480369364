import LoadingSpinner from 'components/Misc/LoadingSpinner';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import styles from './Utilisation.module.css';
import { FormattedMessage } from 'react-intl';

const fetchUtilisation = async (setUtilisationTherme, setLoading, setError) => {
  const response = await fetch('/api/utilisationdisplay/checkins');
  if (!response.ok) {
    const errorResponse = await response.json();
    setError(errorResponse?.error);
    setLoading(false);
    return;
  }
  const responseJson = await response.json();
  setUtilisationTherme(responseJson.utilisationTherme);
  setLoading(false);
};

export default function Utilisation() {
  const [utilisationTherme, setUtilisationTherme] = useState(null);
  const [error, setError] = useState(true);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Load utilisation data and refresh every 5 minutes.
    fetchUtilisation(setUtilisationTherme, setLoading, setError);
    const five_minutes = 300_000;
    const timerId = setInterval(
      () => fetchUtilisation(setUtilisationTherme, setLoading, setError),
      five_minutes
    );
    return () => clearTimeout(timerId);
  }, []);

  return (
    <div className="absolute bottom-[3rem] right-0 z-30 w-24 rounded-bl-lg bg-primary drop-shadow-lg lg:bottom-auto lg:top-2/4 lg:w-28">
      <div className="mb-4 px-2 pb-3 pt-5 text-center text-sidemenu-m leading-tight text-tt-white lg:px-2 lg:pb-4 lg:pt-8 lg:text-sidemenu-d lg:leading-tight">
        <div className="mb-6 lg:mb-8">
          {loading && <LoadingSpinner size={10} className="mx-auto mb-6" />}
          {!loading && utilisationTherme != null && (
            <div>
              <span className="block font-bold">{utilisationTherme}%</span>
              <p className="font-thin">
                <FormattedMessage
                  defaultMessage={`Auslastung Therme`}
                  id="YtS4uR"
                />
              </p>
            </div>
          )}
          {error && <div>{error}</div>}
        </div>
        <div className="flex flex-col gap-6 lg:gap-8">
          <UtilisationIcon
            link="/oeffnungszeiten-tarife"
            label="Öffnungszeiten / Tarife"
            iconClass={styles.oeffnungszeiten}
          />
          <UtilisationIcon
            link="/my-tamina"
            label="My Tamina"
            iconClass={styles.mytamina}
          />
          <UtilisationIcon
            link="/onlineshop"
            label="Onlineshop"
            iconClass={styles.shop}
          />
        </div>
      </div>
    </div>
  );
}

function UtilisationIcon({ link, label, iconClass }) {
  return (
    <div>
      <Link
        href={link}
        passHref
        className={`group mx-auto block h-[2.375rem] w-[2.375rem] bg-center bg-no-repeat lg:flex lg:h-[4.25rem] lg:w-[4.25rem] ${iconClass}`}
      >
        <span
          className={`absolute left-0 hidden h-[2.375rem] -translate-x-full items-center whitespace-nowrap pr-4 text-base-m font-bold text-menu group-hover:flex lg:h-[4.25rem] lg:text-base-d`}
        >
          {label}
        </span>
      </Link>
    </div>
  );
}
