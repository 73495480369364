import Link from 'next/link';
import Image from 'next/image';
import React, { useEffect, useRef } from 'react';
import Glide, {
  Controls,
  Breakpoints,
  Swipe,
} from '@glidejs/glide/dist/glide.modular.esm';
import '@glidejs/glide/dist/css/glide.core.min.css';
import { absoluteUrl } from 'lib/utils';
import ArrowRight from '../../../public/images/arrow-right.svg';
import ArrowLink from 'components/Misc/ArrowLink';

export default function P10NewsTeaser({ view }) {
  const ref = useRef();

  const cards = view?.results;

  useEffect(() => {
    const gallery_settings = {
      type: 'carousel',
      startAt: 0,
      perView: 3,
      gap: '16',
      focusAt: 'center',
      breakpoints: {
        920: {
          perView: 2,
        },
        640: {
          gap: '0',
          perView: 1,
        },
      },
    };

    new Glide(ref.current, gallery_settings).mount({
      Controls,
      Breakpoints,
      Swipe,
    });
  }, []);

  return (
    <div
      ref={ref}
      data-paragraph-type="P10NewsTeaser"
      className="glide mx-auto max-w-5xl px-14 lg:px-8"
    >
      <div className="glide__track" data-glide-el="track">
        <ul className="glide__slides">
          {cards?.length ? (
            cards.map((card) => (
              <li key={card.id} className="glide__slide">
                <div
                  className={`relative h-96 w-full bg-primary text-secondary lg:h-128`}
                >
                  {card?.field_teaser_image?.field_media_image && (
                    <Link href={card?.path?.alias ?? ''} passHref>
                      <Image
                        width={324}
                        height={2}
                        src={absoluteUrl(
                          card.field_teaser_image.field_media_image.uri.url
                        )}
                        alt=""
                        className="h-32 w-full object-cover lg:h-48"
                      />
                    </Link>
                  )}
                  <div className="text-clip py-4 px-6 lg:py-8 lg:px-8">
                    <Link href={card?.path?.alias ?? ''}>
                      <h3 className="mb-4 text-h3-m font-light uppercase text-tt-white line-clamp-2 lg:text-link-d">
                        {card?.title}
                      </h3>
                    </Link>
                    <p className="hyphens-auto mb-3 text-base-m font-light leading-tight text-tt-white line-clamp-5 lg:text-base-d">
                      {card?.field_teaser_text}
                    </p>
                    {card?.field_teaser_link_text && (
                      <div className="absolute bottom-0 left-0 right-0 px-6 py-4 lg:px-8 lg:py-8">
                        <ArrowLink inverted href={card?.path?.alias ?? ''}>
                          {card?.field_teaser_link_text}
                        </ArrowLink>
                      </div>
                    )}
                  </div>
                </div>
              </li>
            ))
          ) : (
            <p className="py-4">No images found</p>
          )}
        </ul>
      </div>

      <div className="glide__arrows" data-glide-el="controls">
        <button
          className="glide__arrow--left absolute inset-y-1/2 left-2 block lg:-left-5 "
          data-glide-dir="<"
        >
          <ArrowRight
            width={39}
            height={19}
            className="rotate-180 fill-primary"
          ></ArrowRight>
        </button>
        <button
          className="glide__arrow glide__arrow--right absolute inset-y-1/2 right-2 block lg:-right-5"
          data-glide-dir=">"
        >
          <ArrowRight
            width={39}
            height={19}
            className="rotate-0 fill-primary"
          ></ArrowRight>
        </button>
      </div>
    </div>
  );
}
