import PreviewAlert from 'components/PreviewAlert';
import Header from 'components/blocks/Header';
import Footer from 'components/blocks/Footer';
import { DrupalMenuLinkContent, DrupalNode } from 'next-drupal';
import Utilisation from 'components/blocks/Utilisation';
import Metatags from 'components/Metatags';
import FooterPartnerLogos from 'components/blocks/FooterPartnerLogos';
import WrapperPopup from 'components/blocks/WrapperPopup';
import WrapperLightBox from 'components/Misc/LightBox/WrapperLightBox';
import LanguageSwitcher from 'components/blocks/LanguageSwitcher';

export interface LayoutProps {
  entity: DrupalNode;
  menus: {
    main: DrupalMenuLinkContent[];
    footer: DrupalMenuLinkContent[];
    stickyShop: DrupalMenuLinkContent[];
  };
  children?: React.ReactNode;
}

export default function Layout({ entity, menus, children }) {
  const theme = entity?.field_theme ?? '';
  return (
    <>
      <Metatags entity={entity} />
      <div className={`${theme} text-secondary`}>
        <PreviewAlert />
        <Header menus={menus} />
        {/* <LanguageSwitcher /> */}
        <Utilisation />
        <div className="relative h-full">
          <main>
            <WrapperPopup>
              <WrapperLightBox>{children}</WrapperLightBox>
            </WrapperPopup>
          </main>
        </div>
        <Footer menu={menus.footer} />
        <FooterPartnerLogos />
      </div>
    </>
  );
}
