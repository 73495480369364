import { useState } from 'react';

export default function P13Accordion({ paragraph }) {
  const [isOpen, setIsOpen] = useState(false);
  let expandAccordionItem = 'invisible hidden';
  let toggleBackgroundColor = 'bg-primary';
  const theme = paragraph?.field_theme ?? '';
  let toggleStylingAccordionTitle =
    theme === 'gastronomy' ? 'text-white' : 'text-secondary';

  if (isOpen) {
    expandAccordionItem = 'visible block';
    toggleBackgroundColor =
      'border-solid border-2 border-primary bg-transparent';
    toggleStylingAccordionTitle = 'text-secondary mb-6';
  } else {
    expandAccordionItem = 'invisible hidden';
    toggleBackgroundColor = 'bg-primary';
    toggleStylingAccordionTitle =
      theme === 'gastronomy' ? 'text-white' : 'text-secondary';
  }

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      onClick={handleClick}
      className={`mx-auto mb-5 max-w-4xl cursor-pointer px-6 sm:px-6 lg:px-8 ${theme}`}
    >
      <h2 className="text-h2-m font-light uppercase text-secondary lg:text-h2-d">
        {paragraph?.title}
      </h2>
      <section
        className={`relative mx-auto px-8 py-4 ${toggleBackgroundColor}`}
      >
        <p
          className={`m-0 block w-72 text-left text-base-m font-bold ${toggleStylingAccordionTitle} lg:w-auto lg:text-base-d`}
        >
          {paragraph?.field_title}
        </p>
        <div
          className={`absolute top-8 right-4 transform-gpu transition-all`}
        ></div>
        <div
          className={`${expandAccordionItem} prose mb-6 text-base-m font-light lg:text-base-d`}
          dangerouslySetInnerHTML={{
            __html: paragraph?.field_body_formatted?.processed,
          }}
        />
      </section>
    </div>
  );
}
