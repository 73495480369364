import { useQuery } from '@tanstack/react-query';
import PopUp from 'components/Misc/PopUp';
import { absoluteUrl } from 'lib/utils';
import { useEffect, useState } from 'react';
import { isPublished } from 'lib/utils';

interface PopupInterface {
  body: string;
  title: string;
  subTitle: string;
  delayInSeconds: number;
  frequencyInSeconds: number;
  publishOn: string;
  unpublishOn: string;
}

const loadBlockPopup = async () => {
  const response = await fetch(absoluteUrl('/jsonapi/block_content/popup'), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return processResponse(await response.json());
};

const processResponse = (response): PopupInterface => {
  const attributes = response?.data[0]?.attributes;
  if (!attributes) {
    return null;
  }
  return {
    body: attributes?.body?.processed,
    title: attributes?.field_title,
    subTitle: attributes?.field_subtitle,
    delayInSeconds: attributes?.field_delay,
    frequencyInSeconds: attributes?.field_frequency,
    publishOn: attributes?.field_publish_on,
    unpublishOn: attributes?.field_unpublish_on,
  };
};

const isTimeToShowAgain = (frequencyInSeconds) => {
  const lastShown = localStorage.getItem('popup_last_shown');
  if (!lastShown) {
    localStorage.setItem('popup_last_shown', String(Date.now()));
    return true;
  }
  // Calculate elapsed time and compare to frequency.
  const timeStampLastShown = parseInt(lastShown);
  const timeStampNow = Date.now();
  const elapsedTimeInSeconds = Math.floor(
    (timeStampNow - timeStampLastShown) / 1000
  );
  if (elapsedTimeInSeconds > frequencyInSeconds) {
    localStorage.setItem('popup_last_shown', String(Date.now()));
    return true;
  }
  return false;
};

const WrapperPopup = ({ children }) => {
  const { isLoading, isError, data } = useQuery({
    queryKey: ['popup'],
    queryFn: loadBlockPopup,
  });
  const [show, setShow] = useState(false);
  useEffect(() => {
    let timer;
    if (data && isTimeToShowAgain(data?.frequencyInSeconds)) {
      // Schedule popup to show up depending on set delay.
      timer = setTimeout(() => {
        setShow(true);
      }, data?.delayInSeconds * 1000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [data]);

  if (
    isError ||
    isLoading ||
    !isPublished(data?.publishOn, data?.unpublishOn)
  ) {
    return <>{children}</>;
  }

  return (
    <>
      <PopUp setShow={setShow} show={show}>
        <div className="curved">{data?.title}</div>
        <div className="my-3 text-xl uppercase">{data?.subTitle}</div>
        <div
          className="popup prose mb-5 text-base"
          dangerouslySetInnerHTML={{ __html: data?.body }}
        />
      </PopUp>
      <>{children}</>
    </>
  );
};

export default WrapperPopup;
