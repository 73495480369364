interface SubtitleProps {
  children: React.ReactNode;
  className?: string;
  isSpan?: boolean;
}

export default function Subtitle({
  children,
  className,
  isSpan = false,
}: SubtitleProps) {
  if (isSpan) {
    return (
      <span
        className={`block text-h3-m font-light uppercase lg:text-h3-d ${
          className ?? ''
        }`}
      >
        {children}
      </span>
    );
  }
  return (
    <h3 className={`text-h3-m uppercase lg:text-h3-d ${className ?? ''}`}>
      {children}
    </h3>
  );
}
