import { WebformCustomComponent } from 'nextjs-drupal-webform';
import * as React from 'react';
import { useEffect, useState } from 'react';

export default function WebformCheckbox({
  element,
  setValue,
  fieldProps,
  value,
}): WebformCustomComponent {
  if (element['#type'] !== 'checkbox' && element['#type'] !== 'radio') {
    console.warn(
      `${element['#type']} which was used on ${element['#webform_key']} is not supported by WebformCheckbox.`
    );
  }

  const [checkedState, setCheckedState] = useState(
    value ?? (element['#checked'] ? element['#checked'] : false)
  );

  // Update component state when value changes in upstream state.
  useEffect(() => {
    if (typeof value === 'boolean') {
      setCheckedState(value);
    }
  }, [value]);

  const onChangeHandler = async (e) => {
    setValue(e.target.checked);
  };

  return (
    <>
      <div className="mt-2">
        <label className="flex cursor-pointer gap-x-2 text-base-m lg:gap-x-4 lg:text-base-d">
          <input
            className="relative h-4 w-4 cursor-pointer border-0 text-tt-white before:absolute before:left-[2px] before:top-[2px] before:h-3 before:w-3 checked:bg-none checked:before:bg-primary focus:outline-none focus:ring-0 focus:ring-offset-0 lg:h-8 lg:w-8 before:lg:left-1 before:lg:top-1 before:lg:h-6 before:lg:w-6"
            type={element['#type']}
            name={element['#name']}
            onChange={(e) => onChangeHandler(e)}
            disabled={element['#disabled']}
            hidden={!(element['#access'] ? element['#access'] : true)}
            required={element['#required']}
            readOnly={element['#readonly']}
            checked={checkedState}
            id={element['#id']}
            {...fieldProps}
            value={element['#return_value']}
          />
          <span
            className="lg:pt-1"
            dangerouslySetInnerHTML={{
              __html: element['#description']?.['#text'] || element['#title'],
            }}
          ></span>
        </label>
      </div>
    </>
  );
}
