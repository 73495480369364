import NewsletterForm from './NewsletterForm';
import SponsoringForm from './SponsoringForm';
import { Webform } from 'nextjs-drupal-webform';
import WebformWrapper from 'components/webforms/WebformWrapper';
import WebformText from 'components/webforms/WebformText';
import WebformButton from 'components/webforms/WebformButton';
import WebformSelect from 'components/webforms/WebformSelect';
import WebformDate from 'components/webforms/WebformDate';
import WebformCheckbox from 'components/webforms/WebformCheckbox';
import WebformTime from 'components/webforms/WebformTime';
import ConfirmationPage from 'components/webforms/ConfirmationPage';
import WebformTextArea from 'components/webforms/WebformTextArea';

export default function P15Webform({ paragraph, webform }) {
  switch (
    paragraph?.field_webform?.resourceIdObjMeta?.drupal_internal__target_id
  ) {
    case 'newsletter':
      return <NewsletterForm paragraph={paragraph} />;
    case 'sponsoringanfrage':
      return <SponsoringForm paragraph={paragraph} />;
    case '':
    case null:
    case undefined:
      return <div className="mx-auto max-w-4xl">Form not Found</div>;
    default:
      return (
        <WebformWrapper>
          <Webform
            className="flex flex-col gap-2 lg:gap-5"
            id={webform?.id}
            data={webform}
            customComponents={{
              textfield: WebformText,
              email: WebformText,
              number: WebformText,
              tel: WebformText,
              url: WebformText,
              select: WebformSelect,
              date: WebformDate,
              checkbox: WebformCheckbox,
              webform_time: WebformTime,
              textarea: WebformTextArea,
              button: WebformButton,
              confirmation_page: ConfirmationPage,
            }}
          />
        </WebformWrapper>
      );
  }
}
