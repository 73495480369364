import { ButtonHTMLAttributes } from 'react';
import Arrow from 'components/Misc/Arrow';

interface ArrowButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  inverted?: boolean;
  children?: React.ReactNode;
  pointingLeft?: boolean;
}

export default function ArrowButton({
  children,
  inverted,
  pointingLeft,
  ...props
}: ArrowButtonProps) {
  return (
    <button {...props}>
      <Arrow
        disabled={props.disabled}
        inverted={inverted}
        pointingLeft={pointingLeft}
      >
        {children}
      </Arrow>
    </button>
  );
}
