import React, { useCallback } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import Image from 'next/image';
import styles from './FileInput.module.css';

type FileInputProps = {
  error?: any;
  name: string;
  id: string;
  label: string | JSX.Element;
  accept?: Accept;
  multiple?: boolean;
  maxFiles?: number;
  maxSize?: number;
  register: any;
  setValue: any;
  watch: any;
};

const FileInput = ({
  error,
  name,
  id,
  label,
  accept,
  multiple,
  maxFiles,
  maxSize,
  register,
  setValue,
  watch,
  ...props
}: FileInputProps) => {
  const files = watch(name);
  const onDrop = useCallback(
    (droppedFiles) => {
      const newFiles =
        (files?.length && [...files, ...droppedFiles]) || droppedFiles;
      setValue(name, newFiles, { shouldValidate: true });
    },
    [setValue, name, files]
  );
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFileDialogActive,
    isFocused,
  } = useDropzone({
    onDrop,
    accept,
    multiple,
    maxFiles,
    maxSize: maxSize * 1024 ** 2, // convert Megabytes to Bytes
  });

  const removeFile = (file) => {
    files.splice(files.indexOf(file), 1);
    setValue(name, files, { shouldValidate: true });
  };

  return (
    <div className="mt-2 grid grid-cols-6 gap-x-2 gap-y-2 lg:gap-x-4">
      <label
        className="col-span-2 pt-1 text-base-m font-light lg:text-base-d"
        htmlFor={id}
      >
        {label}
      </label>
      <div className="col-span-6 w-full lg:col-span-4">
        <div
          {...getRootProps()}
          id={id}
          className={`rounded-md mt-1 flex w-full cursor-pointer flex-col items-center justify-center border-2 border-solid border-secondary p-5 focus:border-primary ${
            error ? 'border-red-500' : ''
          } ${
            isDragActive || isFocused || isFileDialogActive ? styles.focus : ''
          }`}
        >
          <input {...register} {...getInputProps()} />
          <span className="text-base-m font-normal">Datei hochladen</span>
        </div>
        {!!files?.length && (
          <div className="mt-3 flex flex-col gap-2 text-base-m lg:text-base-d">
            {files.map((file) => {
              return (
                <div className="flex items-center" key={file.name}>
                  <span
                    className="mr-2 flex flex-shrink-0 cursor-pointer"
                    onClick={() => removeFile(file)}
                  >
                    <Image
                      loading="eager"
                      src="/images/icon_trash.svg"
                      width={16}
                      height={16}
                      alt="remove"
                    />
                  </span>
                  {file.name}
                </div>
              );
            })}
          </div>
        )}
        {error && <span className="text-sm text-red-500">{error.message}</span>}
        <div className="mt-3 inline-flex w-full flex-col">
          {multiple && maxFiles && (
            <span className="text-sm">Maximal {maxFiles} Dateien möglich.</span>
          )}
          {accept && (
            <span className="text-sm">
              Erlaubte Dateitypen:{' '}
              {Object.values(accept).map((type, index) => (
                <span key={index}>{type}, </span>
              ))}
            </span>
          )}
          {maxSize && <span className="text-sm">{maxSize} MB Datei Limit</span>}
        </div>
      </div>
    </div>
  );
};

export default FileInput;
