import { absoluteUrl } from 'lib/utils';
import Image from 'next/image';

export default function Block({ block }) {
  const paragraph =
    block?.field_reusable_paragraph?.paragraphs == undefined
      ? block
      : block?.field_reusable_paragraph?.paragraphs;
  const textColor =
    paragraph?.field_color === 'white' ? 'text-secondary' : 'text-white';

  return (
    <div>
      {paragraph ? (
        <div className={`bg-tt-block-${paragraph?.field_color} p-10`}>
          {paragraph?.field_icon && (
            <Image
              width={62}
              height={68}
              src={absoluteUrl(paragraph?.field_icon?.uri?.url)}
              alt=""
              className="mx-auto mb-10 h-[68px]"
            ></Image>
          )}
          <div
            className={`${textColor} text-base-m lg:text-base-d`}
            dangerouslySetInnerHTML={{
              __html: paragraph?.field_formatted_text?.processed,
            }}
          ></div>
        </div>
      ) : null}
    </div>
  );
}
