import ImageWallItem from './ImageWallItem';

export default function P3ImageWall({ paragraph }) {
  const imageWallItems =
    paragraph?.field_image_wall?.field_reusable_paragraph?.paragraphs ==
    undefined
      ? paragraph?.field_image_wall?.field_image_wall_items
      : paragraph?.field_image_wall?.field_reusable_paragraph?.paragraphs
          ?.field_image_wall_items;

  return (
    <section data-paragraph-type="P3ImageWall" className="">
      <div className="m-auto w-screen px-0 lg:max-w-4xl lg:px-8">
        <div className="lg:grid-rows-10 grid grid-cols-12 gap-2 lg:grid-cols-5 lg:gap-4">
          {imageWallItems?.map((imageWallItem, index) => (
            <ImageWallItem
              imageWallItem={imageWallItem}
              index={index}
              key={index}
            />
          ))}
        </div>
      </div>
    </section>
  );
}
