import CountUp from 'react-countup';

export default function CounterItem({ counterItem, index }) {
  return (
    <div key={index}>
      <CountUp
        className="text-counter-m font-medium text-secondary drop-shadow-sm lg:text-counter-d"
        decimals={counterItem.field_number % 1 == 0 ? 0 : 2}
        decimal="."
        separator=""
        enableScrollSpy
        end={counterItem.field_number}
      />
      <p
        className="text-base-m font-light lg:text-base-d"
        dangerouslySetInnerHTML={{
          __html: counterItem.field_text,
        }}
      ></p>
    </div>
  );
}
