import TeaserCard from './TeaserCard';

export default function InformationTeaserGallery({ cards }) {
  return (
    <div
      data-paragraph-type="InformationTeaserGallery"
      className="mx-auto max-w-5xl px-14 lg:px-8"
    >
      <div className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 lg:grid-cols-3">
        {cards?.map((card) => (
          <div key={card.id}>
            <TeaserCard card={card} />
          </div>
        ))}
      </div>
    </div>
  );
}
