export const getError = (error: string, intl) => {
  switch (error) {
    case 'required':
      return intl.formatMessage({
        defaultMessage: 'Dies ist ein Pflichtfeld',
        id: 'g9K0Ij',
      });
    case 'email':
      return intl.formatMessage({
        defaultMessage: 'Keine gültige E-Mail Adresse',
        id: 'FMymm6',
      });
    case 'session':
      return intl.formatMessage({
        defaultMessage:
          'Session ist abgelaufen. Bitte melden Sie sich erneut an.',
        id: 'IW+rkg',
      });
    case 'email_used':
      return intl.formatMessage({
        defaultMessage:
          'Diese E-Mail Adresse wird bereits für eine andere Person verwendet.',
        id: 'w0qxpS',
      });
    default:
      return '';
  }
};
