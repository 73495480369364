import { createContext, useState } from 'react';
import LightBox from '../LightBox';

interface LightBoxContext {
  urlImage: string;
  setUrlImage: any;
  textAlt: string;
  setTextAlt: any;
}
export const LightBoxContext = createContext<LightBoxContext>({
  urlImage: '',
  setUrlImage: () => {},
  textAlt: '',
  setTextAlt: () => {},
});

const WrapperLightBox = ({ children }) => {
  const [urlImage, setUrlImage] = useState('');
  const [textAlt, setTextAlt] = useState('');
  return (
    <LightBoxContext.Provider
      value={{ urlImage, setUrlImage, textAlt, setTextAlt }}
    >
      <LightBox />
      {children}
    </LightBoxContext.Provider>
  );
};

export default WrapperLightBox;
