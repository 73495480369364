import Block from './Block';

export default function P16Blocks({ paragraph }) {
  const blocks = paragraph.field_blocks;

  return (
    <div className="max-w-5xl mx-auto px-14 lg:px-8">
      <div className="block lg:grid grid-cols-3 gap-4">
        {blocks?.map((block) => (
          <div key={block.id} className="mb-10 lg:mb-0">
            <Block block={block} />
          </div>
        ))}
      </div>
    </div>
  );
}
