enum AlignmentOptions {
  Left = 'Left',
  Center = 'Center',
  Right = 'Right',
}

export default function Alignment({ alignment, children }) {
  switch (alignment) {
    case AlignmentOptions.Right:
      return (
        <div className="flex flex-col lg:flex-row">
          <div className="lg:w-1/2" />
          <div className="lg:w-1/2">{children}</div>
        </div>
      );
    case AlignmentOptions.Left:
      return (
        <div className="flex flex-col lg:flex-row">
          <div className="lg:w-1/2">{children}</div>
          <div className="lg:w-1/2" />
        </div>
      );
    case AlignmentOptions.Center:
      return <div>{children}</div>;
  }
}
