interface HeadingProps {
  isH1?: boolean;
  className?: string;
  children: React.ReactNode;
}

const Heading = ({ isH1, className, children }: HeadingProps) => {
  if (isH1) {
    return <h1 className={className}>{children}</h1>;
  }
  return <div className={className}>{children}</div>;
};

export default Heading;
